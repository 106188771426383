<template>
  <div
    class="modal__overlay"
    @click="showReview"
  />
  <vue100vh :css="{ height: '100rvh' }">
    <div class="review">
      <div class="container">
        <div class="review__top">
          <!--          <svg-->
          <!--            width="49"-->
          <!--            height="48"-->
          <!--            viewBox="0 0 49 48"-->
          <!--            fill="none"-->
          <!--            xmlns="http://www.w3.org/2000/svg"-->
          <!--          >-->
          <!--            <path-->
          <!--              d="M20.5 32H26.5M29.5 38L24.5 40L19.5 38L14.5 40V14H34.5V40L29.5 38ZM20.5 20H28.5H20.5ZM28.5 26H20.5H28.5Z"-->
          <!--              stroke="#A7ACBC"-->
          <!--              stroke-width="2.5"-->
          <!--              stroke-linecap="round"-->
          <!--              stroke-linejoin="round"-->
          <!--            />-->
          <!--            <path-->
          <!--              d="M34.5 22H38.5C40.71 22 42.5 20.21 42.5 18V10C42.5 7.79 40.71 6 38.5 6H10.5C8.29 6 6.5 7.79 6.5 10V18C6.5 20.21 8.29 22 10.5 22H14.5"-->
          <!--              stroke="#A7ACBC"-->
          <!--              stroke-width="2.5"-->
          <!--              stroke-linecap="round"-->
          <!--              stroke-linejoin="round"-->
          <!--            />-->
          <!--          </svg>-->
          <svg
            width="81"
            height="84"
            viewBox="0 0 81 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.2903 24.114C17.0929 16.0675 21.2586 13.5313 23.1161 13.2691L58.9261 12.6816C60.7299 13.0294 69.3037 16.283 71.0423 24.1652C82.9674 31.2591 81.0728 38.1279 78.8438 44.8277C82.856 58.509 77.785 60.4795 72.9927 63.4072C70.8752 73.3725 65.5813 78.9463 54.6592 79.9597C44.9631 86.2092 39.8364 84.4075 32.2021 79.9597C19.6083 78.89 16.3763 73.9355 14.6491 66.4474C9.63382 64.0828 2.33387 56.7636 7.79549 45.5034C6.23519 34.2994 6.84816 30.5835 15.2903 24.114Z"
              fill="#00AD63"
            />
            <path
              d="M9.9334 24.1123C11.736 16.0658 19.1593 12.7349 21.0168 12.4727L58.1005 13.2673C59.9044 13.6151 63.9468 16.2813 65.6854 24.1635C77.6105 31.2574 75.7159 38.1262 73.4869 44.826C77.499 58.5073 72.4281 60.4778 67.6358 63.4054C65.5182 73.3708 60.2244 78.9446 49.3023 79.958C39.6062 86.2075 34.4795 84.4058 26.8452 79.958C14.2514 78.8883 11.0194 73.9338 9.29212 66.4457C4.27689 64.0811 -3.02306 56.7619 2.43855 45.5017C0.878257 34.2977 1.49123 30.5818 9.9334 24.1123Z"
              fill="#46C080"
            />
            <ellipse
              cx="36.3671"
              cy="46.7339"
              rx="20.8715"
              ry="21.0874"
              fill="#00AE63"
            />
            <ellipse
              cx="39.9214"
              cy="46.7196"
              rx="20.2271"
              ry="20.4364"
              fill="white"
            />
            <path
              d="M48.2165 37.2875C46.3712 28.4067 40.5494 19.5761 37.8691 16.2709V10.748L49.9908 19.2002C52.1787 22.3557 56.436 28.9597 55.963 30.1318C55.3717 31.5968 50.3456 37.8514 48.2165 37.2875Z"
              fill="#C43332"
            />
            <path
              d="M29.5951 37.6566C31.4403 28.7758 37.2621 19.9452 39.9424 16.64V11.1172L27.8207 19.5694C25.6329 22.7249 21.3755 29.3289 21.8485 30.5009C22.4398 31.9659 27.4659 38.2206 29.5951 37.6566Z"
              fill="#C43332"
            />
            <path
              d="M24.6484 24.4032L26.1745 22.049L38.3094 12.7148L51.7801 21.8401L53.3824 24.3939L42.155 22.5366L39.0301 17.829L35.8995 22.5366L24.6484 24.4032Z"
              fill="#B33232"
            />
            <path
              d="M47.1805 21.5939C45.7675 21.1511 44.5982 20.5445 43.6579 19.8931C41.4449 18.36 40.8336 17.486 40.9027 14.7947C41.019 10.2634 42.9002 3.69728 46.8266 1.43237C47.7517 0.898681 48.4863 0.62273 49.1392 0.481819C50.7499 0.134211 52.4673 0.421988 53.9532 1.1341C55.2091 1.736 56.1227 2.53389 56.8096 3.42792C59.1197 6.43434 59.371 10.5916 58.8328 14.3446C58.4141 17.2636 57.4622 19.028 56.298 20.158C53.978 22.4096 50.2655 22.5608 47.1805 21.5939Z"
              fill="#D93536"
            />
            <path
              d="M58.1894 5.63552C55.1427 -4.57095 45.5456 1.38282 41.1279 5.63552C41.8408 7.35828 43.2667 11.215 43.2667 12.8597C44.7276 15.1265 48.7451 16.3916 51.7713 16.3916C61.2151 14.3357 59.1281 9.16964 58.1894 5.63552Z"
              fill="#F2443C"
            />
            <path
              d="M30.6828 21.5939C32.0958 21.1511 33.2651 20.5445 34.2054 19.8931C36.4184 18.36 37.0297 17.486 36.9606 14.7947C36.8443 10.2634 34.9631 3.69728 31.0367 1.43237C30.1115 0.898681 29.3769 0.62273 28.724 0.481819C27.1134 0.134211 25.396 0.421988 23.9101 1.1341C22.6542 1.736 21.7406 2.53389 21.0536 3.42792C18.7436 6.43434 18.4922 10.5916 19.0305 14.3446C19.4492 17.2636 20.401 19.028 21.5653 20.158C23.8853 22.4096 27.5978 22.5608 30.6828 21.5939Z"
              fill="#D93536"
            />
            <path
              d="M19.6739 5.63552C22.7206 -4.57095 32.3176 1.38282 36.7354 5.63552C36.0224 7.35828 34.5966 11.215 34.5966 12.8597C33.1357 15.1265 29.1182 16.3916 26.092 16.3916C16.6482 14.3357 18.7352 9.16964 19.6739 5.63552Z"
              fill="#F2443C"
            />
            <rect
              x="34.7261"
              y="4.48828"
              width="8.38494"
              height="12.6074"
              rx="4.19247"
              fill="#A82D2D"
            />
            <ellipse
              cx="38.9185"
              cy="8.72412"
              rx="4.19247"
              ry="4.23584"
              fill="#C53332"
            />
            <ellipse
              cx="12.4296"
              cy="24.5011"
              rx="4.17859"
              ry="4.22181"
              fill="#F8C136"
            />
            <mask
              id="mask0_8938_254554"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="8"
              y="20"
              width="9"
              height="9"
            >
              <ellipse
                cx="12.4333"
                cy="24.5049"
                rx="4.18237"
                ry="4.22564"
                fill="#FEDB55"
              />
            </mask>
            <g mask="url(#mask0_8938_254554)">
              <ellipse
                cx="9.17778"
                cy="24.5025"
                rx="3.2559"
                ry="3.28958"
                fill="#FEDB55"
              />
            </g>
            <ellipse
              cx="66.1747"
              cy="45.2335"
              rx="4.17859"
              ry="4.22181"
              fill="#F8C136"
            />
            <mask
              id="mask1_8938_254554"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="61"
              y="41"
              width="10"
              height="9"
            >
              <ellipse
                cx="66.1785"
                cy="45.2374"
                rx="4.18237"
                ry="4.22564"
                fill="#FEDB55"
              />
            </mask>
            <g mask="url(#mask1_8938_254554)">
              <ellipse
                cx="62.9229"
                cy="45.2369"
                rx="3.2559"
                ry="3.28958"
                fill="#FEDB55"
              />
            </g>
            <ellipse
              cx="52.0511"
              cy="71.9542"
              rx="4.17859"
              ry="4.22181"
              fill="#F8C136"
            />
            <mask
              id="mask2_8938_254554"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="47"
              y="67"
              width="10"
              height="10"
            >
              <ellipse
                cx="52.0549"
                cy="71.9581"
                rx="4.18237"
                ry="4.22564"
                fill="#FEDB55"
              />
            </mask>
            <g mask="url(#mask2_8938_254554)">
              <ellipse
                cx="48.7994"
                cy="71.9576"
                rx="3.2559"
                ry="3.28958"
                fill="#FEDB55"
              />
            </g>
            <ellipse
              cx="10.2163"
              cy="66.8962"
              rx="4.35892"
              ry="4.40401"
              fill="#F8C136"
            />
            <mask
              id="mask3_8938_254554"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="5"
              y="62"
              width="10"
              height="10"
            >
              <ellipse
                cx="10.2203"
                cy="66.8963"
                rx="4.36287"
                ry="4.408"
                fill="#FEDB55"
              />
            </mask>
            <g mask="url(#mask3_8938_254554)">
              <ellipse
                cx="6.82415"
                cy="66.8983"
                rx="3.39642"
                ry="3.43155"
                fill="#FEDB55"
              />
            </g>
            <ellipse
              cx="10.7707"
              cy="45.6056"
              rx="3.81224"
              ry="3.85168"
              fill="#D83636"
            />
            <mask
              id="mask4_8938_254554"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="6"
              y="41"
              width="9"
              height="9"
            >
              <ellipse
                cx="10.7742"
                cy="45.6091"
                rx="3.8157"
                ry="3.85517"
                fill="#FEDB55"
              />
            </mask>
            <g mask="url(#mask4_8938_254554)">
              <ellipse
                cx="7.80444"
                cy="45.6106"
                rx="2.97045"
                ry="3.00118"
                fill="#F3443F"
              />
            </g>
            <ellipse
              cx="27.0266"
              cy="72.3282"
              rx="3.81224"
              ry="3.85168"
              fill="#D83636"
            />
            <mask
              id="mask5_8938_254554"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="23"
              y="68"
              width="8"
              height="9"
            >
              <ellipse
                cx="27.0301"
                cy="72.3317"
                rx="3.8157"
                ry="3.85517"
                fill="#FEDB55"
              />
            </mask>
            <g mask="url(#mask5_8938_254554)">
              <ellipse
                cx="24.0608"
                cy="72.3313"
                rx="2.97045"
                ry="3.00118"
                fill="#F3443F"
              />
            </g>
            <ellipse
              cx="67.9548"
              cy="65.5861"
              rx="3.81224"
              ry="3.85168"
              fill="#D83636"
            />
            <mask
              id="mask6_8938_254554"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="64"
              y="61"
              width="8"
              height="9"
            >
              <ellipse
                cx="67.9583"
                cy="65.5895"
                rx="3.8157"
                ry="3.85517"
                fill="#FEDB55"
              />
            </mask>
            <g mask="url(#mask6_8938_254554)">
              <ellipse
                cx="64.989"
                cy="65.591"
                rx="2.97045"
                ry="3.00118"
                fill="#F3443F"
              />
            </g>
            <ellipse
              cx="65.8083"
              cy="25.5548"
              rx="3.81224"
              ry="3.85168"
              fill="#D83636"
            />
            <mask
              id="mask7_8938_254554"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="61"
              y="21"
              width="9"
              height="9"
            >
              <ellipse
                cx="65.8118"
                cy="25.5583"
                rx="3.8157"
                ry="3.85517"
                fill="#FEDB55"
              />
            </mask>
            <g mask="url(#mask7_8938_254554)">
              <ellipse
                cx="62.8425"
                cy="25.5598"
                rx="2.97045"
                ry="3.00118"
                fill="#F3443F"
              />
            </g>
          </svg>

          <div class="review__status">
            Статус заказа: {{ status ? 'Полностью' : 'Частично' }} оплачен
          </div>
          <div class="review__time">
            {{ currentDate }}
          </div>
        </div>

        <div
          v-if="tips_type.value === 'LINK' && tipsLink"
          class="vtb"
        >
          <div
            v-show="tips_type.value === 'LINK' && tipsLink && rating === 0"
            class="white-btn"
            :class="{ 'white-btn--vtb': theme.value === 'VTB' }"
            @click="showReview"
          >
            Вернуться в заказ
          </div>
          <a
            class="main-btn"
            :class="{ 'vtb-theme': theme.value === 'VTB' }"
            :href="tipsLink"
            target="_blank"
          >
            Оставить чаевые
          </a>
        </div>

        <!--        --------------------------ТОЛЬКО ДЛЯ ПИВАЛЬДИ----------------------->
        <div
          v-if="urlNamePivaldi !== -1"
          class="review__pivaldi"
        >
          <div class="review__pivaldi-text">
            <p>
              Оцените, пожалуйста наш ресторан для нас очень важно ваше мнение!
            </p>
            <p>Это займёт не более 2 минут</p>
          </div>
          <div class="review__pivaldi-button">
            <a
              href="https://webanketa.com/forms/6cv32csk6gqk2e9m6ws68s31/"
              target="_blank"
              @click="showReview"
            >
              <button class="main-btn">Оценить обслуживание</button>
            </a>
            <button
              class="main-btn-white"
              @click="showReview"
            >
              Вернуться в заказ
            </button>
          </div>
        </div>

        <!--        --------------------------ТОЛЬКО ДЛЯ ПИВАЛЬДИ----------------------->

        <div
          v-else
          class="review__common"
        >
          <h2 class="review__title">
            Вам всё понравилось?
          </h2>

          <div class="review__stars">
            <div
              class="review__stars-item"
              style="display: none"
              :class="{ active: rating == 0 }"
              @click="handleRating(1)"
            >
              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 15.235L4.679 18L5.888 11.88L1.5 7.392L7.679 6.621L10.5 1L13.321 6.621L19.5 7.392L15.112 11.88L16.321 18L10.5 15.235Z"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div
              class="review__stars-item"
              :class="[
                { 'review__stars-item__VTB': theme.value === 'VTB' },
                { active: rating == 1 },
              ]"
              @click="handleRating(1)"
            >
              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 15.235L4.679 18L5.888 11.88L1.5 7.392L7.679 6.621L10.5 1L13.321 6.621L19.5 7.392L15.112 11.88L16.321 18L10.5 15.235Z"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div
              class="review__stars-item"
              :class="[
                { 'review__stars-item__VTB': theme.value === 'VTB' },
                { active: rating == 2 },
              ]"
              @click="handleRating(2)"
            >
              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 15.235L4.679 18L5.888 11.88L1.5 7.392L7.679 6.621L10.5 1L13.321 6.621L19.5 7.392L15.112 11.88L16.321 18L10.5 15.235Z"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div
              class="review__stars-item"
              :class="[
                { 'review__stars-item__VTB': theme.value === 'VTB' },
                { active: rating == 3 },
              ]"
              @click="handleRating(3)"
            >
              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 15.235L4.679 18L5.888 11.88L1.5 7.392L7.679 6.621L10.5 1L13.321 6.621L19.5 7.392L15.112 11.88L16.321 18L10.5 15.235Z"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div
              class="review__stars-item"
              :class="[
                { 'review__stars-item__VTB': theme.value === 'VTB' },
                { active: rating == 4 },
              ]"
              @click="handleRating(4)"
            >
              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 15.235L4.679 18L5.888 11.88L1.5 7.392L7.679 6.621L10.5 1L13.321 6.621L19.5 7.392L15.112 11.88L16.321 18L10.5 15.235Z"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div
              class="review__stars-item"
              :class="[
                { 'review__stars-item__VTB': theme.value === 'VTB' },
                { active: rating == 5 },
              ]"
              @click="handleRating(5)"
            >
              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 15.235L4.679 18L5.888 11.88L1.5 7.392L7.679 6.621L10.5 1L13.321 6.621L19.5 7.392L15.112 11.88L16.321 18L10.5 15.235Z"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <ul
            v-if="rating > 3"
            class="review__list"
          >
            <li
              v-for="(reviewItem, index) in reviewItems"
              :key="reviewItem.id"
              class="review__list-item"
              :class="{ active: reviewItem.active }"
              @click="addActive(index)"
            >
              <img
                :src="require('../../assets/images/' + reviewItem.image)"
                alt=""
              >

              <span>{{ reviewItem.text1 }}</span>

              <span>{{ reviewItem.text2 }}</span>
            </li>
          </ul>

          <div
            v-if="rating && rating <= 3 && rating > 0"
            class="suggestions"
          >
            <p class="suggestions__title">
              В чём мы можем стать лучше?
            </p>

            <ul class="suggestions__list">
              <li
                v-for="(item, index) in reviewItemsCheckbox"
                :key="item.id"
                class="suggestions__list-item"
              >
                <label class="suggestions__list-checkbox">
                  <span>{{ item.text }}</span>

                  <input
                    v-model="reviewItemsCheckbox[index].active"
                    type="checkbox"
                  >

                  <span
                    class="custom-checkbox"
                    :class="{ 'custom-checkbox__VTB': theme.value === 'VTB' }"
                  />
                </label>
              </li>

              <!-- <li class="suggestions__list-item">
                <label class="suggestions__list-checkbox">
                  <span>Чистота</span>

                  <input type="checkbox">

                  <span class="custom-checkbox" />
                </label>
              </li>

              <li class="suggestions__list-item">
                <label class="suggestions__list-checkbox">
                  <span>Атмосфера</span>

                  <input type="checkbox">

                  <span class="custom-checkbox" />
                </label>
              </li>

              <li class="suggestions__list-item">
                <label class="suggestions__list-checkbox">
                  <span>Качество блюд</span>

                  <input type="checkbox">

                  <span class="custom-checkbox" />
                </label>
              </li> -->
            </ul>
          </div>

          <div
            style="padding: 0 16px"
            class="review__text-wrap"
          >
            <p class="review__text">
              Комментарий
            </p>

            <textarea
              v-model="review"
              class="review__textarea"
              name="review"
              placeholder="Расскажите подробнее..."
            />

            <!--            <button-->
            <!--              v-show="tipsType === 'VTB'"-->
            <!--              class="main-btn__VTB"-->
            <!--              @click="showReview"-->
            <!--            >-->
            <!--              Вернуться в заказ-->
            <!--            </button>-->
            <button
              v-show="tips_type.value !== 'LINK' || !tipsLink || rating > 0"
              class="main-btn"
              :class="{ 'main-btn__VTB': theme.value === 'VTB' }"
              @click="showReview"
            >
              Готово
            </button>
          </div>
        </div>
      </div>
    </div>
  </vue100vh>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
/* eslint-disable */
import vue100vh from 'vue-100vh'
export default {
  name: 'Review',
  components: { vue100vh },
  props: [
    'summaPlusDiscount',
    'waiter',
    'orderId',
    'tableCode',
    'diffCheck',
    'addressee',
    'tipsLink',
    'tipsType',
  ],
  inject: ['theme', 'tips_type'],

  data() {
    return {
      //waiter: null,
      repeatShowReview: false,
      summaAll: 0,
      summaPay: 0,
      payedOrders: 0,
      alienOrderSum: 0,
      tags: '',
      poddomen: '',
      currentDate: '',
      rating: 0,
      review: '',
      status: false,
      urlNamePivaldi: '',
      reviewItemsCheckbox: [
        { id: 0, text: 'Обслуживание', active: false },
        { id: 1, text: 'Чистота', active: false },
        { id: 2, text: 'Атмосфера', active: false },
        { id: 3, text: 'Качество блюд', active: false },
      ],
      reviewItems: [
        {
          id: 0,
          image: 'atmosfera.png',
          text1: 'Прекрасная',
          text2: 'Атмосфера',
          active: false,
        },
        {
          id: 1,
          image: 'clean.png',
          text1: 'Очень',
          text2: 'Чисто',
          active: false,
        },
        {
          id: 2,
          image: 'interier.png',
          text1: 'Красивый',
          text2: 'Интерьер',
          active: false,
        },
        {
          id: 3,
          image: 'service.png',
          text1: 'Великолепное',
          text2: 'Обслуживание',
          active: false,
        },
        {
          id: 4,
          image: 'dish.png',
          text1: 'Изысканные',
          text2: 'Блюда',
          active: false,
        },
      ],
    }
  },

  async mounted() {
    let url = window.location.pathname
    this.urlNamePivaldi = url.indexOf('pivaldi')
    const orderData = localStorage.getItem('order')
    const orderDataParse = await JSON.parse(orderData)
    this.summaAll = parseInt(orderDataParse.summaAll)
    this.summaPay = parseInt(orderDataParse.summaPay)
    this.payedOrders = parseInt(orderDataParse.payedOrders)
    this.alienOrderSum = parseInt(orderDataParse.alienOrderSum)
    this.sendMsgOnLoadComponent(orderDataParse)
    this.status = orderDataParse.status
    //this.waiter = orderDataParse.waiter;

    const full = window.location.host
    const parts = full.split('.')
    this.poddomen = this.$route.params.shop
    this.getDate()
  },
  emits: ['updateInfo', 'togglePreloader', 'showPaidCheck'],
  computed: {
    ...mapGetters('orders', ['GET_CHECK_TRANSACTION']),
  },
  watch: {
    async GET_CHECK_TRANSACTION() {
      console.log('WATCH', this.GET_CHECK_TRANSACTION)
      console.log('WATCH', this.repeatShowReview)
      if (this.GET_CHECK_TRANSACTION && this.repeatShowReview) {
        await this.showReview()
      }
    },
  },
  methods: {
    ...mapActions('send', ['SEND_TELEGRAM_MESSAGE']),
    handleRating(val) {
      this.rating = val
    },

    handleReview(val) {
      this.review = val
    },

    async showReview() {
      console.log('showReview() START')
      this.repeatShowReview = true
      this.$emit('togglePreloader', true)
      if (!this.GET_CHECK_TRANSACTION) {
        return
      }

      this.$emit('showPaidCheck', false)
      await this.$emit('updateInfo')
      console.log('showReview')

      this.tags = ''
      const rew = this.reviewItems.filter((i) => i.active)
      const rew2 = this.reviewItemsCheckbox.filter((i) => i.active)
      if (this.rating > 3 && rew.length > 0) {
        rew.forEach((i, index) => {
          let zpt = index + 1 == rew.length ? ' ' : ', '
          this.tags += i.text1 + ' ' + i.text2 + zpt
        })
      }
      if (this.rating > 0 && this.rating < 4 && rew2.length > 0) {
        rew2.forEach((i, index) => {
          let zpt = index + 1 == rew2.length ? ' ' : ', '
          this.tags += i.text + zpt
        })
      }
      await this.sendMsg()
      this.$emit('showReview', false)
      let baseUrl = window.location.href.split('?')[0]
      window.history.pushState('name', '', baseUrl)

      this.repeatShowReview = false
      this.$emit('togglePreloader', false)
      // location.reload()
    },

    addActive(index) {
      this.reviewItems[index].active = !this.reviewItems[index].active
    },

    sendMsgOnLoadComponent(value) {
      const full = window.location.host
      const parts = full.split('.')
      const poddomen = this.$route.params.shop
      const waiterName = value.waiter?.display_name
        ? value.waiter.display_name
        : value.waiter?.name || ' '

      const tipsInfo =
        this.tips_type.value !== 'LINK'
          ? 'Чаевые: ' + value.tips + 'р' + '\n'
          : ''

      let text =
        'Заказ# ' +
        value.orderId +
        '\n' +
        'Стол: ' +
        value.tableCode +
        '\n' +
        'Официант: ' +
        waiterName +
        '\n' +
        'Сумма в чеке: ' +
        value.summaAll +
        'р' +
        '\n' +
        // 'Сумма оплаты: ' + value.summaPlusDiscount + 'р' + '\n' +
        'Сумма оплаты: ' +
        value.summaPay +
        'р' +
        '\n' +
        tipsInfo +
        'Статус оплаты: ' +
        (parseInt(value.summaAll) - parseInt(value.summaPay) > 0
          ? 'Частично'
          : 'Полностью') +
        ' оплачено' +
        '\n' +
        'Дата заказа: ' +
        value.date

      const req = {
        domen: poddomen,
        message: text,
      }
    },

    async sendMsg() {
      let pozelaniya =
        this.tags.length > 0 ? 'Пожелания: ' + this.tags + '\n' : ''
      const waiterName = this.waiter?.display_name
        ? this.waiter.display_name
        : this.waiter?.name
        ? this.waiter.name
        : 'Неизвестный официант'

      let text =
        'Заказ# ' +
        this.orderId +
        '\n' +
        'Стол: ' +
        this.tableCode +
        '\n' +
        'Официант: ' +
        waiterName +
        '\n' +
        'Комментарий: ' +
        this.review +
        '\n' +
        'Рейтинг: ' +
        this.rating +
        '\n' +
        pozelaniya

      const req = {
        shop_code: this.$route.params.shop,
        message: text,
        user_id: this.waiter && this.waiter.name ? this.waiter.user.id : null,
        message_type: 'RATING',
      }
      await this.SEND_TELEGRAM_MESSAGE(req)
    },
    getDate() {
      const date = new Date()
      const seconds =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      const minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const hours = date.getHours()
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      this.currentDate = `${day}.${month}.${year} | ${hours}:${minutes}:${seconds}`
    },
  },
}
</script>

<style lang="scss">
.review {
  position: fixed;
  top: 0;
  padding-top: 12px;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border-radius: 12px 12px 0px 0px;
  height: 100vh;
  z-index: 2;

  padding-bottom: 45px;
  &__wrapper {
    min-height: 244px;
  }
  .container {
    height: 100%;
    overflow: auto;
    padding-bottom: 80px;
  }
  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;
    max-width: 320px;
    border-bottom: 1px solid #edeef1;
    margin: 0 auto 16px auto;
    svg {
      margin-bottom: 24px;
    }
  }
  &__pivaldi {
    padding: 16px 16px 0 16px;
    &-text {
      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #010d35;
        text-align: center;
      }
    }
    &-button {
      margin-top: 16px;
      //.main-link {
      //  width: 100%;
      //  margin-bottom: 12px;
      //
      //  font-family: $base-font;
      //  text-align: center;
      //  font-weight: 600;
      //  font-size: 17px;
      //  line-height: 22px;
      //  letter-spacing: 0.03em;
      //  color: #fff;
      //
      //  border-radius: 12px;
      //  border: none;
      //
      //  outline: none;
      //  cursor: pointer;
      //
      //  background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
      //}
      //
      //.main-link:disabled {
      //  color: #000000;
      //  background: #edeef1;
      //}
      .main-btn__VTB {
        background: red;
      }
      .main-btn-white {
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-bottom: 12px;

        font-family: $base-font;
        text-align: center;
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: 0.03em;
        color: #413dff;

        border-radius: 12px;
        border: 1px solid #d5dbf1;

        outline: none;
        cursor: pointer;

        background: #fff;
      }

      .main-btn-white:disabled {
        color: #000000;
        background: #edeef1;
      }
    }
  }

  &__status {
    color: #010d35;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px;
  }
  &__time {
    color: #4e5672;
    font-size: 13px;
    line-height: 18px;
  }

  &__title {
    margin-bottom: 15px;

    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #010d35;
    text-align: center;
  }

  &__stars {
    display: flex;
    justify-content: space-between;
    width: 162px;
    margin: 0 auto;
    margin-bottom: 28px;

    &-item {
      & svg {
        fill: #ffd140;
        stroke: #ffd140;
      }

      &.active {
        & ~ * {
          svg {
            fill: #edeef1;
            stroke: #edeef1;
          }
        }
      }

      &__VTB {
        & svg {
          fill: #ee8f40;
          stroke: #ee8f40;
        }

        &.active {
          & ~ * {
            svg {
              fill: #fff;
              stroke: #ee8f40;
            }
          }
        }
      }
    }
  }

  &__list {
    display: flex;
    margin-bottom: 24px;

    font-weight: 500;
    font-size: 13px;
    line-height: 18px;

    overflow-x: scroll;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 16px;

      list-style-type: none;

      color: #80869a;

      & img {
        opacity: 0.4;
      }

      &.active {
        span {
          color: #010d35;
        }

        Img {
          opacity: 1;
        }
      }
    }
  }

  .suggestions {
    padding: 0 16px;
    &__title {
      margin-bottom: 12px;

      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      color: #343d5d;
      text-align: center;
    }

    &__list {
      margin-bottom: 24px;

      &-item {
        margin-bottom: 12px;

        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #343d5d;

        list-style-type: none;

        &:last-child {
          margin-bottom: 0;
        }

        & .suggestions__list-checkbox {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & input {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:checked + .custom-checkbox {
              background-image: url('~@/assets/images/checked.65527f52.svg');
              background-repeat: no-repeat;
              background-position: center center;
            }

            &:checked + .custom-checkbox__VTB {
              background-image: url('~@/assets/images/checked_VTB.svg');
              background-position: center center;
              background-repeat: no-repeat;
            }
          }

          & .custom-checkbox {
            content: '';
            display: inline-block;
            width: 14px;
            height: 14px;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1.5px solid #dcdee3;
            border-radius: 0.25em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 12px;
          }
        }
      }
    }
  }

  &__text {
    padding-bottom: 8px;

    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #343d5d;

    border-bottom: 1px solid #edeef1;
  }

  &__textarea {
    width: 100%;
    height: 120px;
    margin-bottom: 24px;

    border: none;
    outline: none;
    resize: none;

    font-size: 16px;
    padding: 5px 0;

    @media (min-width: 768px) {
      font-size: 14px;
    }

    &::placeholder {
      font-family: Commissioner;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }
  }

  .vtb-theme {
    background: linear-gradient(223.61deg, #4c7dd5 99.99%, #413dff 100%);
  }

  // &__send-btn {
  //     width: 100%;
  //     padding: 16px 0;
  //     margin-bottom: 12px;

  //     font-weight: 600;
  //     font-size: 17px;
  //     line-height: 22px;
  //     color: #343D5D;

  //     background: #EDEEF1;
  //     border-radius: 12px;
  //     border: none;
  // }
}

.vtb {
  padding: 0 16px;
  padding-bottom: 16px;
  position: relative;
  a {
    display: block;
  }
}

.review__text-wrap {
  display: flex;
  flex-direction: column;
  min-height: 220px;
  button {
    margin-top: auto;
  }
}
.white-btn {
  height: 53px;
  display: flex;
  border: 1px solid #5272ff;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #5272ff;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 80px;
  position: relative;
  &--vtb {
    border-color: #4c7dd5;
    color: #4c7dd5;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 320px;
    height: 1px;
    background: #edeef1;
  }
}
</style>
