<template>
  <div
    class="modal__overlay"
    @click="showChoicePayment"
  />
  <div
    class="choicePayment-modal"
    :class="{ hide: chngeOrderStatus }"
  >
    <div class="container">
      <button
        class="closeModal"
        @click="showChoicePayment"
      >
        <img
          src="@/assets/images/close_icon.svg"
          alt="Закрыть"
        >
      </button>

      <div class="info">
        <div class="info__icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
              stroke="#413DFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.749 8C11.6772 8 11.6125 8.03028 11.5671 8.07874C11.5251 8.12349 11.4995 8.18375 11.5 8.25C11.5 8.29461 11.5117 8.3365 11.5322 8.37276C11.5751 8.4487 11.6566 8.5 11.75 8.5C11.8434 8.5 11.9249 8.4487 11.9678 8.37276C11.9883 8.3365 12 8.29461 12 8.25C12 8.17383 11.9659 8.10559 11.9119 8.05972C11.8681 8.02249 11.8113 8 11.749 8Z"
              fill="#C4C4C4"
            />
            <path
              d="M12 17V12H11M11.9119 8.05972C11.9659 8.10559 12 8.17383 12 8.25C12 8.29461 11.9883 8.3365 11.9678 8.37276M11.9119 8.05972C11.8681 8.02249 11.8113 8 11.749 8C11.6772 8 11.6125 8.03028 11.5671 8.07874M11.9119 8.05972L11.749 8.45312L11.5671 8.07874M11.5671 8.07874C11.5251 8.12349 11.4995 8.18375 11.5 8.25C11.5 8.29461 11.5117 8.3365 11.5322 8.37276M11.5322 8.37276C11.5751 8.4487 11.6566 8.5 11.75 8.5C11.8434 8.5 11.9249 8.4487 11.9678 8.37276M11.5322 8.37276L11.75 8.25L11.9678 8.37276"
              stroke="#413DFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <p class="info__text">
          Если у&nbsp;вас включен VPN, пожалуйста выключите его, чтобы избежать
          проблем с&nbsp;оплатой
        </p>

        <div class="info__vpn">
          VPN
        </div>
      </div>

      <vGpayButton
        :payment-callback="paymentCallback"
        :disabled-pay-card-btn="disabledPayCardBtn"
        @togglePayCardBtn="togglePayCardBtn"
        @payCard="payCard"
        @hasDiff="handlerDifference"
      />

      <VOrderYapay
        :discount-field="discountField"
        :sum="sum"
        :summa-pay="summaPay"
        :order-id="order_id"
        :checked-items-pay="checkedItemsPay"
        :items="item"
        :seller-amount="seller_amount"
        :summ-transaction="summTransaction"
        :guest-id="guestId"
        :waiter="waiter"
        :payment-callback="paymentCallback"
        :table-code="tableCode"
        @onHandler="showPayment"
        @getToken="getToken"
        @hasDiff="handlerDifference"
      />
      <!-- <vApplePay
        :sum="sum"
        :seller_amount="seller_amount"
        :summ-transaction="summTransaction"
        :summa-pay="summaPay"
        :checked-items-pay="checkedItemsPay"
        :return-link="returnLink"
        @onHandler="showPayment"
        @getToken="getToken"
        @showAlertModal="closeAllWithAlert"
      /> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'
import axios from 'axios'
import vApplePay from '@/components/paymentButtons/v-apple-pay'
import vGpayButton from '@/components/order/v-gpay-button.vue'
import VOrderYapay from '@/components/paymentButtons/v-order-yapay'
export default {
  name: 'Choicepayment',
  components: {
    VOrderYapay,
    vApplePay,
    vGpayButton,
  },
  props: {
    chngeOrderStatus: {
      type: Boolean,
      required: false,
    },
    discountField: {
      type: Number,
    },
    sum: {
      type: Number,
      required: false,
    },
    seller_amount: {
      type: Number,
      required: false,
    },
    item: {
      type: Object,
      required: false,
    },
    diffCheck: {
      type: Boolean,
      required: false,
    },
    summTransaction: {
      type: Number,
      required: false,
    },
    summaPay: {
      type: Number,
      required: true,
    },
    order_id: {
      type: String,
      required: true,
    },
    checkedItemsPay: {
      type: Array,
      required: true,
    },
    tableCode: {
      type: Number,
      required: true,
    },
    waiter: {
      type: String,
    },
    guestId: {
      type: Number,
      required: true,
    },
    paymentCallback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      appleToken: '',
      yandex_token: '',
      appleRegisterID: '',
      returnLink: '',
      disabledPayCardBtn: false,
    }
  },

  methods: {
    ...mapActions('alertModalText', ['SET_NEW_TEXT']),
    togglePayCardBtn(val) {
      this.disabledPayCardBtn = val
    },
    async payCard(payment) {
      const full = window.location.href
      let extra_service_amount =
        (this.sum - this.seller_amount - this.summTransaction) * 100
      let requestArray = {
        url: full,
        order_id: this.order_id,
        position_id: this.checkedItemsPay,
        amount: this.summaPay * 100 + this.seller_amount * 100,
        seller_amount: this.seller_amount * 100,
        extra_service_amount: this.summaPay * 100,
        summTransaction: this.summTransaction * 100,
        safePayFlag: 'Y',
        tableCode: this.tableCode,
        waiter: this.waiter,
        guestId: this.guestId,
      }

      console.log('ID Официанта и столик', requestArray)

      if (payment && payment.payment == 'apple') {
        requestArray['applePaymentToken'] = payment.token
        requestArray['paymentRegisterID'] = payment.registerID
        requestArray['shippingContact'] = payment.shippingContact
        requestArray['transactionID'] = payment.transactionID
        requestArray['applePayStatus'] = 'authorize'
      }

      if (payment && payment.payment == 'yandex') {
        requestArray['yandexPaymentToken'] = payment.yandex_token
        requestArray['paymentRegisterID'] = payment.registerID
        requestArray['shippingContact'] = payment.shippingContact
          ? payment.shippingContact
          : ''
        requestArray['transactionID'] = payment.transactionID
        requestArray['yandexPayStatus'] = 'authorize'
      }

      // let queryString = JSON.stringify(requestArray);
      // axios.post('https://apitapper.ru/api/best2pay/payorder/', requestArray)
      await axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/best2pay/payorder/`,
          requestArray
        )
        .then((response) => {
          console.log(response)
          this.returnLink = response.data
          // window.open(response.data.result)

          //это
          location.href = response.data.result
        })
        .catch((error) => {
          console.log(error)
        })
      this.togglePayCardBtn(false)
    },
    showChoicePayment() {
      this.$emit('showChoicePayment', false)
    },

    showPayment() {
      this.$emit('showPayment', true)
    },
    closeAllWithAlert() {
      console.log('closeAllWithAlert')
      this.SET_NEW_TEXT(
        'В данный момент' +
          '<br/>' +
          'оплата занята' +
          '<br/>' +
          'другим пользователем.' +
          '<br/>' +
          'Повторную попытку оплаты' +
          '<br/>' +
          'можно совершить через 30 секунд'
      )
      this.$emit('showAlertModal', 'on')
      this.$emit('showChoicePayment', false)
      setTimeout(() => {
        this.$emit('showAlertModal', 'off')
      }, 3000)
    },
    handlerDifference() {
      // this.SET_NEW_TEXT( 'Состав заказа изменился')
      this.SET_NEW_TEXT('Сумма заказа изменилась')
      this.$emit('showAlertModal', 'on')
      this.$emit('showChoicePayment', false)
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    },
    async getToken(val) {
      console.log('val2', val)

      this.appleToken = val.token ? val.token : null
      this.yandex_token = val.yandex_token ? val.yandex_token : null

      this.appleRegisterID = val.registerID
      console.log('this.appleToken1', val)

      await this.payCard(val)
    },
  },
}
</script>

<style lang="scss">
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: #dcdee3;
  opacity: 0.9;

  z-index: 1;

  &--payment {
    opacity: 1 !important;
    background: rgba(220, 222, 227, 0.5) !important;
    backdrop-filter: blur(12.5px);
  }
}
.choicePayment-modal {
  position: fixed;
  bottom: 45px;

  // padding-top: 45px;
  padding: 45px 16px 0 16px;
  width: 100%;

  background-color: #fff;

  z-index: 2;
  border-radius: 12px 12px 0px 0px;
  .container {
    padding: 16px 1px 50px;
  }

  & .closeModal {
    position: absolute;
    top: 12px;
    right: 16px;

    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    outline: none;
    background: rgba(219, 222, 231, 0.5);
    cursor: pointer;

    border-radius: 50%;
    border: none;
  }

  & .google {
    margin-right: 4px;
  }

  & .applePay {
    background: #fff;
    border: 1px solid #e6e7eb;

    & img {
      vertical-align: middle;
    }
  }

  & .googlePay {
    background: #241c2d;

    & img {
      vertical-align: middle;
    }
  }
  &.hide {
    display: none;
  }

  & .info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 12px;
    margin-bottom: 24px;
    border: 1px solid #6764ff;
    border-radius: 11px;
    font-family: $ptroot-font;
    font-size: 12px;
    font-weight: 500;

    &__icon {
      display: flex;
      flex-shrink: 0;
    }

    &__text {
      margin: 0 12px 0 8px;
    }

    &__vpn {
      flex-shrink: 0;
      padding: 2px 4px;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      color: #343d5d;
    }
  }
}
</style>
