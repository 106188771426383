<template>
  <div class="check">
    <div
      v-if="diffCheck"
      class="check__total"
    >
      <span class="check__total-heading">Общий счёт:</span>

      <span
        class="check__total-sum"
      >{{ summaAll + alienOrderSum }} <span
        v-html="currencyIcon"
      /></span>
    </div>

    <div class="check__position">
      <div
        v-if="alienOrderSum"
        class="check__marked"
      >
        <span class="check__tips-heading">Другой пользователь:</span>

        <span
          class="check__tips-sum"
        >- {{ alienOrderSum }} <span
          v-html="currencyIcon"
        /></span>
      </div>
      <div
        v-if="diffCheck"
        class="check__marked"
      >
        <span class="check__tips-heading">Отмеченные позиции:</span>

        <span
          class="check__tips-sum"
        >{{ summaChecked }} <span
          v-html="currencyIcon"
        /></span>
      </div>

      <div
        v-if="waiter && waiter.card_verification && enabledTips"
        class="check__tips"
      >
        <span class="check__tips-heading"> Чаевые: </span>

        <span
          class="check__tips-sum"
        >{{ tips }} <span
          v-html="currencyIcon"
        /></span>
      </div>
      <div
        v-if="discountField"
        class="check__tips"
      >
        <span class="check__tips-heading">{{
          discountField > 0 ? 'Наценка:' : 'Скидка:'
        }}</span>

        <span
          class="check__tips-sum"
        >{{ discountField }} <span
          v-html="currencyIcon"
        /></span>
      </div>
    </div>

    <div class="check__totalPay">
      <span class="check__totalPay-heading">Итого к оплате:</span>

      <span
        v-if="summaPlusDiscount >= 0"
      >{{ summaPlusDiscount }} <span
        v-html="currencyIcon"
      /></span>
      <span v-else>0 <span v-html="currencyIcon" /></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderCheck',
  inject: ['theme', 'tips_type'],

  props: {
    diffCheck: {
      type: Boolean,
      required: true,
      default: false,
    },
    enabledTips: {
      type: Boolean,
      required: false,
      default: true,
    },
    summaAll: {
      type: Number,
      required: true,
      default: 0,
    },
    alienOrderSum: {
      type: Number,
      required: true,
      default: 0,
    },
    summaChecked: {
      type: Number,
      required: true,
      default: 0,
    },
    tips: {
      type: Number,
      required: true,
      default: 0,
    },
    summaPlusDiscount: {
      type: Number,
      required: true,
      default: 0,
    },
    discountField: {
      type: Number,
      required: true,
      default: 0,
    },
    waiter: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    currencyIcon: {
      type: String,
      default: '-',
    },
  },
}
</script>

<style scoped lang="scss">
.check {
  margin-bottom: 24px;
  padding: 12px;

  border: 1px solid #dbdee7;
  border-radius: 11px;

  background-color: #fff;

  &__discount {
    display: block;
    margin-bottom: 16px;
    text-align: center;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #9796b1;
    text-decoration: none;

    &.active {
      color: #6764ff;
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;

    font-weight: 600;
    font-size: 17px;
    line-height: 22px;

    border-bottom: 1px solid #edeef1;
  }

  &__position {
    padding-bottom: 16px;
    padding-top: 8px;

    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    border-bottom: 1px solid #edeef1;

    & .check__tips {
      display: flex;
      justify-content: space-between;

      color: #343d5d;
    }

    & .check__marked {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
    }
  }

  &__totalPay {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;

    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
}
</style>
