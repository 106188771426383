<template>
  <div class="vBlockPayModal">
    <div
      class="vBlockPayModal__overlay"
      @click="$emit('toggleBlockPayModal')"
    />
    <div class="vBlockPayModal__content">
      <div
        class="vBlockPayModal__close"
        @click="$emit('toggleBlockPayModal')"
      >
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_8770_238651)">
            <rect
              y="0.5"
              width="24"
              height="24"
              rx="12"
              fill="#F7F8FA"
            />
            <path
              d="M16 8.5L8 16.5M8 8.5L16 16.5L8 8.5Z"
              stroke="#EC4E4E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <rect
            x="0.5"
            y="1"
            width="23"
            height="23"
            rx="11.5"
            stroke="#EDEEF1"
          />
          <defs>
            <clipPath id="clip0_8770_238651">
              <rect
                y="0.5"
                width="24"
                height="24"
                rx="12"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="vBlockPayModal__title">
        Технические работы
      </div>
      <div class="vBlockPayModal__description">
        На данный момент ведутся технические работы. Попробуйте оплатить заказ
        позже, или обратитесь к официанту
      </div>
      <vButton @click="$emit('toggleBlockPayModal')">
        Закрыть
      </vButton>
    </div>
  </div>
</template>

<script>
import vButton from '@/components/v-button'
export default {
  name: 'VBlockPayModal',
  components: {
    vButton,
  },
  emits: ['toggleBlockPayModal'],
}
</script>

<style lang="scss" scoped>
.vBlockPayModal {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  &__overlay {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    background: #dcdee3;
    opacity: 0.9;
    backdrop-filter: blur(26.5px);
    z-index: 1;
  }
  &__content {
    padding: 20px 16px;
    background: #fff;
    border-radius: 12px;
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 343px;
  }
  &__title {
    color: #010d35;
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;
  }
  &__description {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 24px;
  }
  &__close {
    position: absolute;
    right: 16px;
    top: 20px;
    z-index: 1;
  }
}
</style>
