<template>
  <button
    class="vGpayButton"
    :disabled="disabledPayCardBtn"
    @click="payCard"
  >
    Оплата картой
    <img
      src="~@/assets/images/preloaderRomashka.svg"
      class="vGpayButton__preloader"
    >
  </button>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'VGpayButton',
  props: {
    gpay: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabledPayCardBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    paymentCallback: {
      type: Function,
      required: true,
    },
  },
  emits: ['payCard', 'has-diff', 'togglePayCardBtn'],
  data() {
    return {
      disabled: false,
    }
  },
  unmounted() {
    // this.disabled = false
    // this.$emit('togglePayCardBtn', false)
    clearTimeout(this.resetDisabledBtn)
  },
  methods: {
    async payCard() {
      // this.disabled = true
      this.$emit('togglePayCardBtn', true)
      this.paymentCallback().then((diff) => {
        if (!diff) {
          this.$emit('payCard')
        }
      })
    },
    resetDisabledBtn() {
      setTimeout(() => {
        this.disabled = false
      }, 5000)
    },
  },

  ...mapActions({
    setNewAlertText: 'alertModalText/SET_NEW_TEXT',
  }),
}
</script>
<style lang="scss">
.vGpayButton {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 12px;
  font-family: 'Commissioner';
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.03em;
  color: #fff;
  border-radius: 12px;
  border: none;
  outline: none;
  cursor: pointer;
  background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:disabled {
    color: #80869a;
    cursor: no-drop;
    background: #edeef1;
    &:hover {
      color: #80869a;
      background: #edeef1;
    }
    .vGpayButton__preloader {
      display: inline-block;
    }
  }
  &__preloader {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    position: absolute;
    left: calc(50% + 70px);
    transform: translate(-50%, -50%);
    top: 50%;
    display: none;
  }
  &__img {
    margin-left: 10px;
  }
}
</style>
