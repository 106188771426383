<template>
  <div
    class="modal__overlay"
    @click="close"
  />
  <div class="callWaiter-modal">
    <form
      class="container"
      @submit.prevent="showCallWaiter"
    >
      <div
        v-if="addressee === 'MANAGER'"
        class="callWaiter__heading"
      >
        Вызов менеджера
      </div>

      <div
        v-if="addressee !== 'MANAGER'"
        class="callWaiter__heading"
      >
        Вызов официанта
      </div>

      <div
        class="callWaiter-modal__close"
        @click="close"
      >
        <img src="@/assets/images/closeBtn.svg">
      </div>

      <div v-if="!notificationSending">
        <textarea
          v-model.trim="msg"
          class="callWaiter__textarea"
          name="callWaiter"
          placeholder="Комментарий..."
          :required="true"
        />

        <div class="callWaiter__control">
          <button
            class="callWaiter-btn send"
            :class="{ send__VTB: theme.value === 'VTB' }"
            type="submit"
          >
            Отправить
          </button>

          <div
            class="callWaiter-btn cancel"
            @click="close"
          >
            Отменить
          </div>
        </div>
      </div>

      <div v-if="notificationSending">
        <div class="successfulSending">
          <VIconSuccessfulSending />
          <p>{{ successfullText }}</p>
        </div>
        <button
          class="successfulSendin__btn"
          @click="closeCallingWaiter"
        >
          Закрыть
        </button>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'
import VIconSuccessfulSending from '@/assets/images/icons/v-icon-successful-sending'
import VButton from '@/components/v-button'
export default {
  name: 'Callwaiter',
  components: {
    VButton,
    VIconSuccessfulSending,
  },
  inject: ['theme'],
  props: {
    tableCode: {
      type: String,
      default: '1',
    },
    waiter: {
      type: Object,
      default: null,
    },
    successfullText: {
      type: String,
      default: '',
    },
    addressee: {
      type: String,
      default: '',
    },
    emptyModal: {
      type: Boolean,
      default: false,
    },
    tipsType: {
      type: String,
      required: false,
      default: 'TAPPER',
    },
  },
  data() {
    return {
      msg: '',
      currentDate: null,
      poddomen: '',
      notificationSending: false,
    }
  },
  mounted() {
    this.getDate()
    const full = window.location.host
    const parts = full.split('.')
    this.poddomen = this.$route.params.shop
  },
  methods: {
    ...mapActions('send', ['SEND_TELEGRAM_MESSAGE', 'SHOP_INFORMATION']),
    close() {
      this.$emit('showCallWaiter', false)
    },
    showCallWaiter() {
      const newMsg =
        '🖐 Вызов официанта' +
        '\n' +
        'Номер столика: ' +
        this.tableCode +
        '\n' +
        'Комментарий: ' +
        this.msg +
        '\n' +
        'Время: ' +
        this.currentDate
      this.msg = ''

      const req = {
        shop_code: this.poddomen,
        message: newMsg,
        message_type: 'WAITER_CALL',
      }

      if (
        !Array.isArray(this.waiter) &&
        this.waiter &&
        this.waiter.user &&
        this.waiter.user.id &&
        this.addressee !== 'MANAGER'
      ) {
        req.user_id = this.waiter.user.id
      }

      this.SEND_TELEGRAM_MESSAGE(req)
      this.notificationSending = !this.notificationSending
    },
    closeCallingWaiter() {
      this.$emit('showCallWaiter', false)
    },
    getDate() {
      const date = new Date()
      const seconds =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      const minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const hours = date.getHours()
      const day = date.getDate()
      const month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      const year = date.getFullYear()
      this.currentDate = `${day}.${month}.${year} | ${hours}:${minutes}:${seconds}`
    },
  },
}
</script>

<style lang="scss">
.callWaiter-modal {
  .container {
    padding: 16px 16px 50px;
  }
  position: fixed;
  bottom: 45px;

  padding-top: 16px;
  width: 100%;

  background-color: #fff;

  z-index: 2;
  border-radius: 12px 12px 0 0;
  &__close {
    position: absolute;
    right: 16px;
    cursor: pointer;
    top: 32px;
  }
  & .callWaiter__heading {
    padding-bottom: 16px;
    margin-bottom: 16px;

    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;

    border-bottom: 1px solid #edeef1;
  }

  & .callWaiter__textarea {
    width: 100%;
    height: 200px;
    margin-bottom: 24px;

    border: none;
    outline: none;
    resize: none;

    color: #343d5d;
    font-family: 'Commissioner', sans-serif;
    font-weight: 500;
    font-size: 16px;

    &::placeholder {
      font-family: 'Commissioner', sans-serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }

  & .callWaiter__control {
    display: flex;
    justify-content: space-between;

    & .callWaiter-btn {
      min-width: 164px;
      padding: 16px 0;

      font-family: 'Commissioner', sans-serif;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: 0.03em;

      cursor: pointer;
      border-radius: 12px;
      border: none;
    }

    & .send {
      background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
      color: #fff;
      &__VTB {
        background: #4c7dd5;
      }
    }

    & .cancel {
      color: #343d5d;
      background: #edeef1;
      text-align: center;
    }
  }
}
.successfulSending {
  background-color: #fff;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #010d35;
    padding-top: 10px;
    text-align: center;
  }
}
.successfulSendin__btn {
  min-width: 100%;
  padding: 16px 0;

  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.03em;

  cursor: pointer;
  border-radius: 12px;
  border: none;
}
</style>
