<template>
  <div
    class="dishList__item"
    :class="{
      paid: item.status === 'PAID',
      active: diffCheck,
      disabled: paymentCheck(item.id),
    }"
  >
    <p
      v-if="
        !item.loading &&
          !paymentCheck(item.id) &&
          item.status === 'NOT_PAID' &&
          diffCheck
      "
      class="dishList__item-status"
    >
      Не оплачено
    </p>
    <p
      v-else-if="item.status === 'NOT_PAID' && !diffCheck"
      class="dishList__item-status"
    />
    <p
      v-else-if="
        (!paymentCheck(item.id) && !item.loading) ||
          (item.status === 'PAID' && !item.loading)
      "
      class="dishList__item-status"
    >
      Оплачено
    </p>

    <p
      v-if="item.loading"
      class="dishList__item-status"
    >
      <img
        src="~@/assets/images/spinner.gif"
        alt="Фото официанта"
        class="dishList__spinner"
      >
      Подождите
    </p>

    <p
      v-if="paymentCheck(item.id) && item.status === 'NOT_PAID'"
      class="dishList__item-status"
    >
      <img
        src="~@/assets/images/spinner.gif"
        alt="Фото официанта"
        class="dishList__spinner"
      >
      Оплачивается
    </p>

    <div class="dishList__item-dish">
      <label
        class="dish__checkbox"
        :class="{ dish__checkbox__VTB: theme.value === 'VTB' }"
      >
        <input
          :checked="item.selected_guest_id === guest"
          type="checkbox"
          :disabled="
            paymentCheck(item.id) || item.status === 'PAID' || item.loading
          "
          @click.prevent="
            diffCheck &&
              checkPosition(item.id, item.selected_guest_id === guest)
          "
        >
        <span v-if="diffCheck" />
        <div class="dish__checkbox-content">
          <p class="dish__checkbox-text">{{ item.name }}</p>
          <p
            v-for="mod in item.modifiers"
            :key="mod.guid"
            class="dish__checkbox-text-small"
          >
            {{ mod.name }}
          </p>
        </div>
      </label>

      <div
        class="dishList__item-total"
        :class="{ 'dishList__item-total__VTB': theme.value === 'VTB' }"
      >
        <span class="sum">{{ item.price }} <span v-html="currencyIcon" /></span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OrderDishItem',
  inject: ['theme'],
  inject: ['theme'],
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    diffCheck: {
      type: Boolean,
      default: false,
      required: true,
    },

    currencyIcon: {
      type: String,
      default: '-',
    },
  },
  emits: ['checkPosition'],

  computed: {
    ...mapGetters({
      getOrders: 'orders/GET_ORDERS',
      guest: 'meta/guest',
    }),
  },

  methods: {
    checkPosition(id, status) {
      const data = {
        id: id,
        status: status,
      }
      this.$emit('checkPosition', data)
    },

    paymentCheck(id) {
      const orderGuestId = this.getOrders.find(
        (order) => order.id === id
      )?.selected_guest_id
      if (orderGuestId) {
        return orderGuestId !== this.guest
      }
      return false
    },
  },
}
</script>

<style scoped lang="scss">
.dishList__item {
  margin-top: 10px;
  padding: 12px;
  border: 1px solid #e6e7eb;
  border-radius: 12px;
  list-style-type: none;
  background-color: #fff;
  min-height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.active {
    min-height: 75px;
  }

  &.paid {
    & .dishList__item-dish {
      & .dish__checkbox {
        & input:checked + span {
          background-image: url('~@/assets/images/checked_paid.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  &-status {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;

    color: #80869a;
  }

  &-dish {
    display: flex;
    justify-content: space-between;

    & .dish__checkbox {
      display: flex;
      align-items: center;
      &__VTB {
        & input {
          position: absolute;
          z-index: -1;
          opacity: 0;

          &:checked + span {
            background-image: url('~@/assets/images/checked_VTB.svg') !important;
            background-position: center center;
            background-repeat: no-repeat;
          }

          &:disabled + span {
            background-color: #dcdee3;
          }
        }
      }

      & span {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1.5px solid #dcdee3;
        border-radius: 0.25em;
        margin-right: 21px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px;
      }

      & input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked + span {
          background-image: url('~@/assets/images/checked.65527f52.svg');
        }

        &:disabled + span {
          background-color: #dcdee3;
        }
      }

      &-text {
        display: inline-flex;
        align-items: center;
        max-width: 170px;

        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &-total {
    display: flex;
    flex-direction: column;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: right;

    color: #413dff;

    &__VTB {
      color: #4c7dd5;
    }
    & .count {
      display: flex;
      align-items: center;
      margin-top: 8px;
      padding: 3px 7px;

      border: 1px solid #edeef1;
      border-radius: 6px;
      background: #f7f8fa;

      font-weight: normal;
      font-size: 13px;
      line-height: 16px;

      &__quantity {
        font-size: 13px;
        line-height: 18px;

        color: #80869a;
      }

      &__btn {
        padding: 0;

        outline: none;
        border: none;
        background-color: inherit;
        cursor: pointer;

        & svg {
          vertical-align: middle;
        }
      }

      & span {
        margin: 0 11px;
      }
    }
  }
}
.dishList__item-dish {
  align-items: center;
}
</style>
