<template>
  <div class="vServiceInvalid">
    <VWarningRed />
    <div class="vServiceInvalid__info">
      Cервис временно <br>
      недоступен
    </div>
    <div class="vServiceInvalid__advice">
      Пожалуйста, обратитесь к официанту
    </div>
  </div>
</template>

<script>
import VWarningRed from '@/assets/images/icons/v-warning-red'

export default {
  name: 'VServiceInvalid',
  components: {
    VWarningRed,
  },
}
</script>
<style lang="scss">
.vServiceInvalid {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #f7f8fa;
  z-index: 99;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__info {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    margin-top: 24px;
  }
  &__advice {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
  }
}
</style>
