<template>
  <div
    class="modal__overlay"
    @click="showShareCheck"
  />
  <div class="shareCheck">
    <div class="container">
      <div class="shareCheck__header">
        <div class="shareCheck__header-logo">
          <img
            src="@/assets/images/share_icon.svg"
            alt=""
          >

          <div class="shareCheck__header-text">
            <p class="shareCheck__header-title">
              Tapper.ru
            </p>

            <p class="shareCheck__header-link">
              tapper.ru/response_type=code...
            </p>
          </div>
        </div>

        <button
          class="shareCheck__header-close"
          @click="showShareCheck"
        >
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.6255 0.625582C12.153 0.153102 11.3869 0.153166 10.9143 0.625723L6.92336 4.61668L3.00878 0.702095C2.5363 0.229615 1.7702 0.229679 1.29764 0.702236C0.825084 1.17479 0.825021 1.9409 1.2975 2.41338L5.21208 6.32796L1.22113 10.3189C0.748569 10.7915 0.748506 11.5576 1.22099 12.0301C1.69347 12.5025 2.45957 12.5025 2.93213 12.0299L6.92308 8.03896L10.8377 11.9535C11.3101 12.426 12.0762 12.426 12.5488 11.9534C13.0214 11.4808 13.0214 10.7147 12.5489 10.2423L8.63436 6.32768L12.6253 2.33672C13.0979 1.86417 13.0979 1.09806 12.6255 0.625582Z"
              fill="#3C3C43"
              fill-opacity="0.6"
            />
          </svg>
        </button>
      </div>

      <div class="shareCheck__persons">
        <ul class="shareCheck__list">
          <li class="shareCheck__list-item">
            <img
              src="@/assets/images/person1.png"
              alt=""
            >

            <span>Майя</span>

            <span>Жаневская</span>
          </li>

          <li class="shareCheck__list-item">
            <img
              src="@/assets/images/person2.png"
              alt=""
            >

            <span>Женя</span>

            <span>Мацура</span>
          </li>

          <li class="shareCheck__list-item">
            <img
              src="@/assets/images/friends.png"
              alt=""
            >

            <span>Друзья</span>

            <span class="gray-text">8 Человек</span>
          </li>

          <li class="shareCheck__list-item">
            <img
              src="@/assets/images/person3.png"
              alt=""
            >

            <span>Ляйсан</span>

            <span>Гуренко</span>
          </li>
        </ul>
      </div>

      <div class="social">
        <ul class="shareCheck__list">
          <li class="shareCheck__list-item">
            <img
              src="@/assets/images/airdrop.png"
              alt=""
            >

            <span>Airdrop</span>
          </li>

          <li class="shareCheck__list-item">
            <img
              src="@/assets/images/messages_icon.png"
              alt=""
            >

            <span>Messages</span>
          </li>

          <li class="shareCheck__list-item">
            <img
              src="@/assets/images/insta_icon.png"
              alt=""
            >

            <span>Instagram</span>
          </li>

          <li class="shareCheck__list-item">
            <img
              src="@/assets/images/whatsapp_icon.png"
              alt=""
            >

            <span>WhatsApp</span>
          </li>
        </ul>
      </div>

      <ul class="action__list">
        <li class="action__list-item">
          <span>Копировать</span>

          <svg
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.25537 19.1528H10.3818C11.9565 19.1528 12.7402 18.3252 12.7402 16.7432V15.4761H14.1831C15.7505 15.4761 16.5708 14.6484 16.5708 13.0664V6.94336C16.5708 5.97656 16.373 5.36133 15.7871 4.76807L12.1396 1.04736C11.583 0.483398 10.9312 0.263672 10.0815 0.263672H7.07861C5.51123 0.263672 4.69092 1.09131 4.69092 2.67334V4.02832H3.25537C1.68799 4.02832 0.867676 4.86328 0.867676 6.44531V16.7432C0.867676 18.3252 1.68799 19.1528 3.25537 19.1528ZM12.7402 10.8765C12.7402 9.8877 12.6157 9.42627 12.0005 8.79639L8.03076 4.76807C7.4668 4.18945 6.97607 4.04297 6.15576 4.02832V2.76855C6.15576 2.10205 6.50732 1.72852 7.21045 1.72852H10.1841V5.33203C10.1841 6.24023 10.6675 6.72363 11.5684 6.72363H15.106V12.9712C15.106 13.645 14.7617 14.0112 14.0513 14.0112H12.7402V10.8765ZM11.7515 5.51514C11.4951 5.51514 11.3926 5.40527 11.3926 5.15625V1.99219L14.8569 5.51514H11.7515ZM3.37988 17.688C2.67676 17.688 2.3252 17.3218 2.3252 16.6479V6.54053C2.3252 5.8667 2.67676 5.49316 3.38721 5.49316H5.7749V9.47754C5.7749 10.5396 6.29492 11.0522 7.34961 11.0522H11.2754V16.6479C11.2754 17.3218 10.9238 17.688 10.2207 17.688H3.37988ZM7.48877 9.77051C7.18848 9.77051 7.05664 9.63867 7.05664 9.33838V5.68359L11.085 9.77051H7.48877Z"
              fill="black"
            />
          </svg>
        </li>

        <li class="action__list-item">
          <span>Добавить в список для чтения</span>

          <svg
            width="23"
            height="9"
            viewBox="0 0 23 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.33643 8.76758C8.62158 8.76758 10.4819 6.9292 10.5625 4.69531C10.8774 4.57812 11.2217 4.53418 11.5 4.53418C11.7856 4.53418 12.1226 4.57812 12.4375 4.69531C12.5254 6.95117 14.3784 8.76758 16.6709 8.76758C18.8462 8.76758 20.6406 7.12695 20.8823 5.0249H21.7832C22.1348 5.0249 22.2666 4.81982 22.2666 4.52686V4.19727C22.2666 3.89697 22.1348 3.70654 21.7832 3.70654H20.8311C20.4429 1.77295 18.729 0.300781 16.6709 0.300781C14.7007 0.300781 13.0601 1.64111 12.5767 3.44287C12.2324 3.31104 11.8296 3.25977 11.5 3.25977C11.1704 3.25977 10.7676 3.31104 10.4233 3.44287C9.93994 1.64111 8.29199 0.300781 6.33643 0.300781C4.28564 0.300781 2.5791 1.7583 2.18359 3.66992H1.22412C0.872559 3.66992 0.733398 3.86035 0.733398 4.16064V4.52686C0.733398 4.81982 0.872559 5.0249 1.22412 5.0249H2.11768C2.35938 7.12695 4.15381 8.76758 6.33643 8.76758ZM6.33643 7.46387C4.71777 7.46387 3.40674 6.16016 3.40674 4.53418C3.40674 2.91553 4.71777 1.61182 6.33643 1.61182C7.94775 1.61182 9.25879 2.91553 9.25879 4.53418C9.25879 6.16016 7.9624 7.46387 6.33643 7.46387ZM16.6709 7.46387C15.0376 7.46387 13.7412 6.16016 13.7412 4.53418C13.7412 2.91553 15.0376 1.61182 16.6709 1.61182C18.2822 1.61182 19.5933 2.91553 19.5933 4.53418C19.5933 6.16016 18.2822 7.46387 16.6709 7.46387Z"
              fill="black"
            />
          </svg>
        </li>

        <li class="action__list-item">
          <span>Добавить в закладки</span>

          <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 1.4458C7.39941 0.735352 6.06641 0.105469 4.49902 0.105469C2.41162 0.105469 0.719727 1.18945 0.272949 2.21484V12.4321C0.272949 13.0986 0.712402 13.355 1.18848 13.355C1.55469 13.355 1.76709 13.2451 1.99414 13.0767C2.41162 12.7324 3.20996 12.2637 4.49902 12.2637C5.79541 12.2637 6.70361 12.7178 7.07715 13.0327C7.29688 13.2012 7.55322 13.355 8 13.355C8.44678 13.355 8.6958 13.1865 8.92285 13.0327C9.31104 12.7397 10.1973 12.2637 11.4937 12.2637C12.79 12.2637 13.5957 12.7397 14.0059 13.0767C14.2329 13.2451 14.4453 13.355 14.8042 13.355C15.2876 13.355 15.7271 13.0986 15.7271 12.4321V2.21484C15.2729 1.18945 13.5811 0.105469 11.4937 0.105469C9.93359 0.105469 8.60059 0.735352 8 1.4458ZM1.75244 2.68359C1.93555 2.23682 2.94629 1.4751 4.49902 1.4751C6.05908 1.4751 7.09912 2.24414 7.26025 2.68359V11.6411C6.57178 11.1431 5.56836 10.8867 4.49902 10.8867C3.42969 10.8867 2.43359 11.1431 1.75244 11.6704V2.68359ZM14.2476 2.68359V11.6704C13.5591 11.1431 12.563 10.8867 11.4937 10.8867C10.4243 10.8867 9.4209 11.1431 8.73975 11.6411V2.68359C8.90088 2.24414 9.94092 1.4751 11.4937 1.4751C13.0537 1.4751 14.0571 2.23682 14.2476 2.68359Z"
              fill="black"
            />
          </svg>
        </li>

        <li class="action__list-item">
          <span>Добавить в избранные</span>

          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.90967 15.8804C4.26123 16.144 4.69336 16.0635 5.19141 15.6973L9 12.8994L12.8013 15.6973C13.2993 16.0635 13.7314 16.144 14.083 15.8804C14.4272 15.624 14.5005 15.1919 14.3027 14.6133L12.8013 10.1382L16.6392 7.38428C17.1372 7.03271 17.3423 6.63721 17.2031 6.22705C17.064 5.81689 16.6758 5.61182 16.0605 5.62646L11.3584 5.65576L9.92285 1.15869C9.73242 0.572754 9.43213 0.257812 9 0.257812C8.56055 0.257812 8.26025 0.572754 8.06982 1.15869L6.6416 5.65576L1.93213 5.62646C1.31689 5.61182 0.928711 5.81689 0.789551 6.22705C0.650391 6.63721 0.862793 7.03271 1.35352 7.38428L5.19141 10.1382L3.68994 14.6133C3.49219 15.1919 3.56543 15.624 3.90967 15.8804ZM5.2207 14.0859C5.20605 14.0713 5.20605 14.064 5.2207 14.02L6.60498 10.1162C6.72949 9.75732 6.69287 9.53027 6.34863 9.30322L2.92822 6.95947C2.89893 6.94482 2.88428 6.93018 2.89893 6.9082C2.90625 6.89355 2.9209 6.88623 2.95752 6.88623L7.10303 6.98877C7.48389 6.99609 7.68164 6.87891 7.7915 6.49805L8.95605 2.52832C8.9707 2.48438 8.97803 2.46973 9 2.46973C9.01465 2.46973 9.0293 2.48438 9.03662 2.52832L10.2012 6.49805C10.311 6.87891 10.5088 6.99609 10.897 6.98877L15.0352 6.88623C15.0791 6.88623 15.0938 6.89355 15.1011 6.9082C15.1084 6.93018 15.0938 6.9375 15.0645 6.95947L11.644 9.30322C11.3071 9.53027 11.2632 9.75732 11.395 10.1162L12.772 14.02C12.7866 14.064 12.7866 14.0713 12.772 14.0859C12.7646 14.1006 12.7427 14.0933 12.7134 14.0713L9.43213 11.5444C9.12451 11.3027 8.86816 11.3027 8.56055 11.5444L5.2793 14.0713C5.25 14.0933 5.23535 14.1006 5.2207 14.0859Z"
              fill="black"
            />
          </svg>
        </li>

        <li class="action__list-item">
          <span>Поиск по странице</span>

          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.77686 12.0771C6.94141 12.0771 8.03271 11.7256 8.94092 11.125L12.1489 14.3403C12.3613 14.5454 12.6323 14.6479 12.9253 14.6479C13.5332 14.6479 13.98 14.1719 13.98 13.5713C13.98 13.293 13.8848 13.022 13.6797 12.8169L10.4937 9.61621C11.1528 8.68604 11.541 7.54346 11.541 6.31299C11.541 3.1416 8.94824 0.548828 5.77686 0.548828C2.60547 0.548828 0.0126953 3.1416 0.0126953 6.31299C0.0126953 9.48438 2.60547 12.0771 5.77686 12.0771ZM5.77686 10.5391C3.45508 10.5391 1.55078 8.63477 1.55078 6.31299C1.55078 3.99854 3.45508 2.08691 5.77686 2.08691C8.09863 2.08691 10.0029 3.99854 10.0029 6.31299C10.0029 8.63477 8.09863 10.5391 5.77686 10.5391Z"
              fill="black"
            />
          </svg>
        </li>
      </ul>

      <a
        href="#"
        class="shareCheck__link"
      >Редактировать действия...</a>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Sharecheck',

  methods: {
    showShareCheck() {
      this.$emit('showShareCheck', false)
    },
  },
}
</script>

<style lang="scss">
.shareCheck {
  position: fixed;
  bottom: 0;

  width: 100%;

  font-family: SFPro;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000;

  border-radius: 13px 13px 0px 0px;
  background: rgb(250, 250, 250);

  z-index: 3;

  &__header {
    display: flex;
    justify-content: space-between;

    padding-bottom: 18px;

    border-bottom: 1px solid rgba(60, 60, 67, 0.36);

    &-logo {
      display: flex;
      align-items: center;

      & img {
        margin-right: 8px;

        // box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
      }
    }

    &-title {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.24px;
    }

    &-link {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.078px;
      color: rgba(60, 60, 67, 0.6);
    }

    &-close {
      width: 30px;
      height: 30px;

      background: rgba(116, 116, 128, 0.08);

      text-align: center;

      border-radius: 50%;
      border: none;
    }
  }

  &__persons {
    padding: 20px 0;

    border-bottom: 1px solid rgba(60, 60, 67, 0.36);
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.066px;
      list-style-type: none;

      & img {
        margin-bottom: 4px;
      }

      & .gray-text {
        color: rgba(60, 60, 67, 0.6);
      }
    }
  }

  & .social {
    padding: 20px 0;
    margin-bottom: 4px;
  }

  & .action__list {
    margin-bottom: 16px;

    background: #ffffff;
    border-radius: 13px;

    &-item {
      display: flex;
      justify-content: space-between;
      padding: 13px 16px 15px;

      border-bottom: 1px solid rgba(60, 60, 67, 0.36);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__link {
    margin-left: 16px;

    color: #007aff;
    text-decoration: none;
  }
}
</style>
