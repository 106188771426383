<script>
import { orderGet } from '@/services/order'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SocketListener',
  computed: {
    ...mapGetters('meta', ['guest']),
    ...mapGetters('orders', ['GET_CHECK_TRANSACTION']),
  },
  mounted() {
    setTimeout(() => {
      this.init().then((session) => {
        console.log('session WS', session)
        window.Echo.channel(
          `${process.env.VUE_APP_PREFIX_SOCKET}session.${session}`
        )
          .listen('ChangeOrderPosition', (event) => {
            console.log('event 1', event)
            switch (event.message) {
              case 'change position':
                this.$store.dispatch('orders/DISABLED_ORDER', event.massive)
                this.$store.commit(
                  'meta/SET_SELECTED_GUEST_ID',
                  event.massive.guest
                )
                break
              case 'update order':
                this.$store.commit('orders/SET_UPDATED', new Date())
                break
              case 'pay position':
                this.$store.dispatch('orders/UPDATE_POSITION', event.massive)
                break
              // case 'pay error':
              //   if (event.massive.guest_id === this.guest)
              //     this.$store.dispatch('orders/UPDATE_ERRORS', event.massive)
              //   break
              default:
                break
            }
          })
          .listen('CloseOrder', (event) => {
            console.log('event 2', event)
            switch (event.message) {
              case 'close order':
                this.$cookies.remove('session')
                //this.$cookies.remove('guest')
                break
              default:
                break
            }
          })
          .listen('PartialPayment', (event) => {
            console.log('event 3', event)
            if (event.message === 'partial payment') {
              if (!this.GET_CHECK_TRANSACTION) {
                this.$store.dispatch(
                  'orders/CHANGE_STATUS_CHECK_TRANSACTION',
                  true
                )
              }
            }
          })
      })
    }, 1000)
  },
  methods: {
    ...mapActions('alertModalText', ['SET_NEW_TEXT']),
    async init() {
      const session = await this.$cookies.get('session')
      const guest = await this.$cookies.get('guest')
      const data = Object.create(null)
      const parts = this.$route.path.split('/')
      data.domen = parts[parts.length - 2]
      data.table_id = parts.pop()

      if (guest) {
        data.guest = guest
      }
      if (session) {
        data.session = session
      }
      const responseData = await orderGet(data)

      if (responseData) {
        const responseDataSession = responseData.hasOwnProperty('orders')
          ? responseData.orders[0].sessions_id.toString()
          : null
        const responseDataGuest = responseData.hasOwnProperty('guest')
          ? responseData.guest?.toString()
          : null
        this.$store.commit('meta/SET_SESSION', responseDataSession)
        this.$store.commit('meta/SET_GUEST', responseDataGuest)

        return session || responseDataSession
      }

      return session
    },
  },
}
</script>
