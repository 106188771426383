<template>
  <div
    v-show="showContent"
    class="vDemoOrder"
  >
    <div
      v-show="!emptyModal && !menu"
      class="newYear__img"
    >
      <div class="newYear__img-wrap newYear__img-left">
        <img
          src="@/assets/images/NY_heading-left.svg"
          alt="Happy New Year!"
        >
      </div>
      <div class="newYear__img-wrap newYear__img-center">
        <img
          src="@/assets/images/NY_heading-center.svg"
          alt="Happy New Year!"
        >
      </div>
      <div class="newYear__img-wrap newYear__img-right">
        <img
          src="@/assets/images/NY_heading-right.svg"
          alt="Happy New Year!"
        >
      </div>

      <div class="newYear__img-line" />
    </div>

    <!-- Header -->
    <OrderHeader
      :table-code="tableCode"
      :separate="separate"
      :menu="menu"
    />

    <!-- Empty order modal -->
    <OrderEmptyModal
      v-if="emptyModal"
      :paid-order="paidOrder"
      :menu="menu"
    />

    <OrderDishList
      v-if="!emptyModal && !menu"
      :not-paid="notPaid"
      :items="items"
      :dish-item="dishItem"
      :diff-check="diffCheck"
      :guest="guest"
      :currency-icon="currencyIcon"
      :discount-field="discountField"
      @diffClick="diffCheckFunc"
      @recount="recount"
      @checkPosition="checkPosition"
    />

    <vMenuDishes v-if="menu" />

    <div v-if="dishItem && notPaid != 0 && !menu">
      <OrderTips
        v-if="(waiter && waiter.name) || (waiter && waiter.display_name)"
        :waiter="waiter"
        :manual-tips="manualTips"
        :percents="percents"
        :tips-value="tips"
        :input-tips="inputTips"
        :available-tips="availableTips"
        :error-tips="errorTips"
        :tips-type="tips_type"
        :tips-link="tips_link"
        :currency-icon="currencyIcon"
        @selectPercents="selectPercents"
        @validTips="validTips"
        @resetTipsZero="resetTipsZero"
        @checkMinTipsError="checkMinTipsError"
      />

      <OrderCheck
        :diff-check="diffCheck"
        :summa-all="summaAll"
        :alien-order-sum="alienOrderSum"
        :summa-checked="summaChecked"
        :tips="tips"
        :summa-plus-discount="
          acquiringCode === 'CloudPayments' && checkTransaction
            ? parseFloat(summaPlusDiscount)
            : summaPlusDiscount
        "
        :enabled-tips="
          tips_type === 'TAPPER' && waiter && waiter.card_verification
        "
        :discount-field="discountField"
        :waiter="waiter"
        :currency-icon="currencyIcon"
      />

      <div class="paymentMethods">
        <p>Способы оплаты</p>
        <img
          class="paymentMethods__img"
          src="~@/assets/images/SBP-image.svg"
        >
        <img
          class="paymentMethods__img"
          src="~@/assets/images/yaPay.svg"
        >
        <img src="~@/assets/images/card.svg">
      </div>

      <form
        action=""
        class="payment"
        @submit.stop.prevent="prevent"
      >
        <CloudPayments
          v-if="acquiringCode === 'CloudPayments'"
          :fee-amount="tips > 0 ? checkTransaction : false"
          :summa-pay="summaPay"
          :acquiring-code="acquiringCode"
          :acc-number="acc_number"
          :discount-field="discountField"
          :sum="sum"
          :diff-check="diffCheck"
          :items="items"
          :order-id="orderId"
          :seller-amount="tips"
          :stop-cloud-pay="stopCloudPay"
          :summ-transaction="tips > 0 ? ((tips / 100) * 24.5).toFixed(2) : 0"
          :guest-id="guest"
          :public-id="publicId"
          :waiter="waiter"
          :error-tips="errorTips"
          :table-code="tableCode"
          :pay-cloud-with-check="payCloudWithCheck"
          :tips="tips"
          :disabled-prop="
            (summaChecked == 0 && diffCheck) ||
              (summaChecked == 0 && separateMaintainer) ||
              (pay && summaChecked == 0) ||
              !paymentConditions ||
              summaPlusDiscount <= 0
          "
          :currency-type="currencyType"
          @cloudOnSuccess="cloudOnSuccess"
          @clickOrder="setOrderDataToLocalStorage"
        />

        <!-- Новая кнопка оплаты -->
        <button
          v-else
          class="payment-btn main-btn"
          :class="{ 'main-btn__VTB': GET_CUSTOMIZATION === 'VTB' }"
          :disabled="
            disabledPayCardBtn ||
              (summaChecked == 0 && diffCheck) ||
              (summaChecked == 0 && separateMaintainer) ||
              (pay && summaChecked == 0) ||
              !paymentConditions ||
              summaPlusDiscount <= 0 ||
              errorTips
          "
          @click="showChoicePayment(true)"
        >
          Оплатить
          <img
            src="~@/assets/images/preloaderRomashka.svg"
            class="vGpayButton__preloader"
          >
        </button>

        <button
          class="share-btn main-btn"
          :disabled="diffCheck && summaChecked == 0"
          @click="shareLink"
        >
          Поделиться счётом

          <span class="share-btn__icon">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8903 13.048L13.9898 12.5968C10.8169 15.2824 7.8331 14.4041 7.35869 16.2161C6.77685 18.4385 7.77646 20.1096 7.91575 20.6576C8.26133 20.92 8.62346 21.1174 8.9582 21.2526C9.15265 21.3315 9.65078 21.4652 9.83469 21.4929C11.2892 21.7143 13.6454 21.2905 14.4174 18.3418C14.8999 16.499 14.9583 14.5626 14.8903 13.048ZM10.8169 15.9473C10.5399 15.8714 10.3765 15.5736 10.4527 15.2824C10.5289 14.9912 10.8154 14.8173 11.0928 14.8934C11.3702 14.9695 11.5333 15.2671 11.4571 15.5579C11.381 15.8486 11.0943 16.0234 10.8169 15.9473ZM11.2118 18.0273C10.9343 17.9512 10.7713 17.6536 10.8474 17.3628C10.9235 17.0721 11.2103 16.8973 11.4877 16.9734C11.7651 17.0495 11.9281 17.3471 11.8519 17.6383C11.7756 17.9295 11.4892 18.1034 11.2118 18.0273ZM12.6865 15.0972C12.4091 15.0211 12.2461 14.7235 12.3222 14.4327C12.3983 14.142 12.685 13.9672 12.9625 14.0433C13.2399 14.1194 13.4028 14.4175 13.3267 14.7082C13.2505 14.9989 12.9639 15.1733 12.6865 15.0972Z"
                fill="#F7C768"
              />
              <path
                opacity="0.8"
                d="M9.83494 21.4901L8.62333 26.1179L10.3763 26.5986L10.1354 27.5187L5.75298 26.3169L5.99385 25.3968L7.74684 25.8775L8.95845 21.2498C7.59066 20.6972 5.75719 19.1245 6.5292 16.1758C7.50957 12.4312 10.1962 9.23839 10.3116 9.10242L10.4962 8.88186L11.3771 9.12342C11.2064 9.36259 8.32151 12.9182 7.4057 16.4161C6.57708 19.581 9.50764 20.4439 9.63035 20.4775C9.75306 20.5112 12.7125 21.2636 13.5411 18.0986C14.324 15.1085 13.9069 11.7499 13.7569 10.7621L11.7453 10.2105C11.9608 9.88994 12.161 9.60958 12.328 9.38421L14.5456 9.99232L14.5977 10.2778C14.6312 10.4546 15.398 14.5945 14.4176 18.339C13.6456 21.2877 11.2895 21.7115 9.83494 21.4901Z"
                fill="#010D35"
              />
              <g clip-path="url(#clip0_8996_238659)">
                <path
                  d="M14.9253 11.7791L15.758 10.483C16.7936 11.5576 22.0432 11.7302 22.8311 13.4239C23.7975 15.5012 23.1735 17.4016 23.1338 17.9664C22.8405 18.2886 22.5194 18.55 22.2142 18.7452C22.037 18.859 21.5708 19.0829 21.3949 19.1444C20.0038 19.6323 17.6117 19.6534 16.3294 16.8971C15.528 15.1746 15.1271 13.2812 14.9253 11.7791ZM19.4453 13.8734C19.7042 13.7473 19.8121 13.4241 19.6855 13.1519C19.5589 12.8797 19.2463 12.762 18.987 12.8883C18.7277 13.0147 18.6202 13.3376 18.7466 13.6094C18.873 13.8812 19.186 13.9998 19.4453 13.8734ZM19.426 15.9922C19.6853 15.8658 19.7928 15.5429 19.6664 15.2711C19.5399 14.9993 19.227 14.8807 18.9677 15.0071C18.7084 15.1334 18.6008 15.4564 18.7275 15.7286C18.8541 16.0008 19.1666 16.1185 19.426 15.9922ZM17.8256 12.5522C18.0849 12.4259 18.1925 12.1029 18.066 11.8311C17.9396 11.5594 17.6266 11.4408 17.3673 11.5671C17.108 11.6935 17.0007 12.0169 17.1271 12.2886C17.2536 12.5604 17.5663 12.6786 17.8256 12.5522Z"
                  fill="#F7C768"
                />
                <path
                  opacity="0.8"
                  d="M21.3949 19.1463L23.4073 23.472L21.7687 24.2705L22.1688 25.1304L26.2652 23.1343L25.8651 22.2744L24.2265 23.0728L22.2141 18.747C23.4612 17.9495 24.9852 16.0623 23.7029 13.306C22.0746 9.80585 18.8663 7.16525 18.7286 7.05299L18.508 6.87039L17.6846 7.2716C17.8949 7.47509 21.3625 10.4355 22.8836 13.7052C24.2599 16.6636 21.5311 18.0566 21.4164 18.1125C21.3017 18.1684 18.5249 19.4581 17.1486 16.4998C15.8484 13.7048 15.6627 10.3245 15.635 9.32527L17.5153 8.40905C17.2466 8.13385 16.9999 7.89535 16.7957 7.70476L14.7229 8.71479L14.7223 9.00518C14.7207 9.18531 14.701 13.3988 16.3293 16.899C17.6116 19.6553 20.0038 19.6342 21.3949 19.1463Z"
                  fill="#010D35"
                />
              </g>
              <defs>
                <clipPath id="clip0_8996_238659">
                  <rect
                    width="17.3162"
                    height="18.0214"
                    fill="white"
                    transform="matrix(-0.898955 0.438041 0.421805 0.906687 24.3984 4)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
        </button>

        <div v-if="GET_CUSTOMIZATION === 'VTB'">
          <label
            v-show="
              acquiringCode !== 'CloudPayments' ||
                (acquiringCode === 'CloudPayments' && tips > 0)
            "
            class="payment__conditions"
          >
            <input
              v-model="checkTransaction"
              type="checkbox"
              checked
              class="payment__conditions-check"
            >

            <p class="payment__conditions-text-VTB">
              Я хочу взять на себя сервисный сбор Tapper ({{
                transactionsSumm
              }}&nbsp;&#8381;)
            </p>
          </label>
          <label class="payment__conditions">
            <input
              v-model="paymentConditions"
              type="checkbox"
              checked
              class="payment__conditions-check"
            >

            <div class="payment__conditions-text-VTB">
              <p>
                <a
                  href="#conf"
                  @click="togglePoliticModal"
                >
                  Согласен с Условиями использования, Политикой
                  конфиденциальности и обработки персональных данных Tapper
                </a>
              </p>
            </div>
          </label>
        </div>
        <div v-else>
          <label
            v-show="
              acquiringCode !== 'CloudPayments' ||
                (acquiringCode === 'CloudPayments' && tips > 0)
            "
            class="payment__conditions"
          >
            <input
              v-model="checkTransaction"
              type="checkbox"
              checked
              class="payment__conditions-check"
            >

            <p class="payment__conditions-text">
              Я хочу взять на себя сервисный сбор Tapper ({{
                transactionsSumm
              }}
              <span v-html="currencyIcon" />)
            </p>
          </label>

          <label class="payment__conditions">
            <input
              v-model="paymentConditions"
              type="checkbox"
              checked
              class="payment__conditions-check"
            >

            <div class="payment__conditions-text">
              <p>
                <a
                  href="#conf"
                  @click="togglePoliticModal"
                >
                  Согласен с Условиями использования, Политикой
                  конфиденциальности и обработки персональных данных Tapper
                </a>
              </p>
            </div>
          </label>
        </div>
      </form>
    </div>
  </div>
  <div
    v-if="!notPayedItems && !menu"
    class="callWaiter__text"
  >
    <span v-if="addressee === 'MANAGER'"> Вызвать менеджера </span>
    <span v-else>Вызвать официанта</span>
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.001 9.99998L6.00001 15.001L0.999009 9.99998M6.00001 15L6.00001 0.999977L6.00001 15Z"
        stroke="#343D5D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div class="menu">
    <div
      v-if="GET_ADMIN_MENU_TEST.length"
      @click="toggleMenu(true)"
    >
      <svg
        class="menuOpen"
        :class="[
          { openActive: menu },
          { openActive__VTB: GET_CUSTOMIZATION === 'VTB' && menu },
        ]"
        width="30"
        height="31"
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5 5.97571C23.8875 5.72521 25.341 5.57971 26.934 5.52571C27.786 5.49721 28.5 6.17221 28.5 7.02421V24.7797C28.5 25.5927 27.8505 26.2647 27.039 26.2767C22.401 26.3472 18.909 27.2022 15 29.5002C11.091 27.2022 7.599 26.3472 2.961 26.2782C2.1495 26.2662 1.5 25.5927 1.5 24.7797V7.02421C1.5 6.18571 2.1885 5.49721 3.0255 5.52421C7.632 5.67271 11.109 6.53971 15 8.71921"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.5 20.8572V2.51818C22.5 1.42468 21.351 0.661183 20.3745 1.15168C17.7405 2.47168 15.5205 5.15518 15 7.91818V29.5002C15.594 26.3517 18.3915 23.3067 21.4965 22.2642C22.0995 22.0617 22.5 21.4932 22.5 20.8572V20.8572Z"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <div
      v-if="addressee === 'MANAGER'"
      class="callWaiter"
      @click="showCallWaiter(true)"
    >
      <img
        v-if="GET_CUSTOMIZATION === 'VTB'"
        src="~@/assets/images/manager_icon_VTB.svg"
        alt=""
      >
      <img
        v-else
        src="~@/assets/images/manager_icon.svg"
        alt=""
      >
    </div>

    <div
      v-else
      class="callWaiter"
      @click="showCallWaiter(true)"
    >
      <img
        v-if="GET_CUSTOMIZATION === 'VTB'"
        src="~@/assets/images/waiterVTB_icon.svg"
        alt=""
      >
      <!--      <img-->
      <!--        v-else-->
      <!--        src="~@/assets/images/waiter_icon.svg"-->
      <!--        alt=""-->
      <!--      >-->
      <img
        v-else
        src="~@/assets/images/NY_waiter_icon.svg"
        alt=""
      >
    </div>

    <div
      v-if="GET_ADMIN_MENU_TEST.length"
      @click="toggleMenu(false)"
    >
      <div>
        <svg
          class="creditCardOpen"
          :class="[
            { openActive: !menu },
            { openActive__VTB: GET_CUSTOMIZATION === 'VTB' && !menu },
          ]"
          width="37"
          height="37"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.56861 15.0514L8.12615 13.4064C7.68061 11.7584 8.65956 10.0641 10.3091 9.62472L25.7921 5.49922C27.4263 5.06447 29.1221 6.04343 29.563 7.67605L32.2671 17.7108C32.7111 19.3588 31.7306 21.0531 30.0841 21.4925L26.825 22.3604"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.625 21.3366H26.825M7.70833 31.6041H23.7417C25.4452 31.6041 26.825 30.2244 26.825 28.5208V18.1346C26.825 16.4311 25.4452 15.0513 23.7417 15.0513H7.70833C6.00479 15.0513 4.625 16.4311 4.625 18.1346V28.5208C4.625 30.2244 6.00633 31.6041 7.70833 31.6041V31.6041Z"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div
          class="priceCard"
          :class="{ priceCard__VTB: GET_CUSTOMIZATION === 'VTB' }"
        >
          <span
            v-if="diffCheck && !paidCheckVisible && notPaid"
          >{{ summaPlusDiscount }} <span
            v-html="currencyIcon"
          /></span>
          <span
            v-if="paidCheckVisible"
          >{{ dishItem ? 0 : summaPlusDiscount }} <span
            v-html="currencyIcon"
          /></span>
          <span
            v-if="!diffCheck && notPaid && !paidCheckVisible"
          >{{ !dishItem ? 0 : summaPlusDiscount }}
            <span
              v-html="currencyIcon"
            /></span>
          <span
            v-if="notPaid == 0 && !paidCheckVisible"
          >{{ notPaid == 0 ? 0 : summaPlusDiscount }}
            <span
              v-html="currencyIcon"
            /></span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <choicepayment
      v-if="choicePaymentVisible && summaPlusDiscount"
      :discount-field="discountField"
      :sum="summaPlusDiscount"
      :item="items"
      :diff-check="diffCheck"
      :seller_amount="tips"
      :summ-transaction="summTransaction"
      :summa-pay="summaPay"
      :order_id="orderId"
      :positions_id="positions_id"
      :checked-items-pay="checkedItemsPay"
      :chnge-order-status="chngeOrderStatus"
      :block-pay="blockPay"
      :waiter="waiter.user ? waiter.user.id : null"
      :table-code="tableCode"
      :guest-id="guest"
      :payment-callback="checkOrder"
      @showChoicePayment="showChoicePayment"
      @showPayment="showPayment"
      @showAlertModal="alertModalToggle"
    />
    <callwaiter
      v-if="callWaiter"
      :waiter="waiter"
      :table-code="tableCode"
      :successfull-text="successfulСhallenge"
      :addressee="addressee"
      :empty-modal="emptyModal"
      :tips-type="tips_type"
      @showCallWaiter="showCallWaiter"
    />
    <PaymentProcess
      v-if="paymentProcess"
      :status="statusPayment"
      :error-message="messageErrorPayment"
      @close="closePaymentProcess"
    />
    <payment
      v-if="paymentVisible"
      :sum="summaPay"
      :item="items"
      :diff-check="diffCheck"
      @showPayment="showPayment"
      @showPaidCheck="showPaidCheck"
      @returnItem="returnItem"
    />
    <sharecheck
      v-if="shareCheckVisible"
      @showShareCheck="showShareCheck"
    />
    <review
      v-if="paidCheckVisible"
      :summa-pay="summaPay"
      :summa-all="summaAll"
      :summa-plus-discount="summaPlusDiscount"
      :waiter="waiter"
      :order-id="orderId"
      :table-code="tableCode"
      :diff-check="diffCheck"
      :tips-link="tips_link"
      :tips-type="tips_type"
      @showReview="showReview"
      @updateInfo="updateInfo"
      @togglePreloader="togglePreloader"
      @showPaidCheck="showPaidCheck"
    />
    <enterphone
      v-if="enterPhoneVisible"
      @showEnterPhone="showEnterPhone"
      @showEnterCode="showEnterCode"
    />
    <entercode
      v-if="enterCodeVisible"
      @showEnterCode="showEnterCode"
    />

    <vLightPreloader v-if="preloader" />
    <vSocketListener />
    <vServiceInvalid v-show="serviceDoNotWorking" />
    <vBlockPayModal
      v-show="blockPayModal"
      @toggleBlockPayModal="toggleBlockPayModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { computed } from 'vue'
import OrderHeader from '@/components/order/OrderHeader'
import OrderEmptyModal from '@/components/order/OrderEmptyModal'
import vLightPreloader from '@/components/v-light-preloader'
import { mapActions } from 'vuex'
import Choicepayment from '@/components/main/Choicepayment'
import Callwaiter from '@/components/main/Callwaiter'
import Payment from '@/components/main/Payment'
import Sharecheck from '@/components/main/Sharecheck'
import CloudPayments from '@/components/cloud-payments'
import Review from '@/components/main/Review'
import Enterphone from '@/components/main/EnterPhone'
import Entercode from '@/components/main/EnterCode'
import vMenuDishes from '@/components/menu/v-menu-dishes'
import axios from 'axios'
import vSocketListener from '@/components/v-socket-listener'
import OrderDishList from '@/components/order/OrderDishList'
import { checkOrderPosition } from '@/services/order'
import OrderTips from '@/components/order/OrderTips'
import OrderCheck from '@/components/order/OrderCheck'
import vServiceInvalid from '@/components/v-service-invalid'
import checkingIfRestWorks from '@/utils/checkingIfRestWorks'
import PaymentProcess from '@/components/main/PaymentProcess'
import vBlockPayModal from '@/components/v-block-pay-modal.vue'
import { TRANSACTION_PERCENTS } from '@/constants'

export default {
  name: 'Order',
  components: {
    PaymentProcess,
    OrderCheck,
    CloudPayments,
    OrderTips,
    OrderDishList,
    vSocketListener,
    OrderHeader,
    OrderEmptyModal,
    Choicepayment,
    Callwaiter,
    Payment,
    Sharecheck,
    Review,
    // Paidcheck,
    Enterphone,
    Entercode,
    vLightPreloader,
    vMenuDishes,
    vServiceInvalid,
    vBlockPayModal,
  },

  provide() {
    return {
      theme: computed(() => this.GET_CUSTOMIZATION),
      tips_type: computed(() => this.tips_type),
    }
  },

  props: {
    showContent: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['LoadScreen', 'onHandler'],

  data() {
    return {
      paymentConditions: true,
      emptyModal: true,
      dishItem: false,
      choicePaymentVisible: false,
      callWaiter: false,
      paymentVisible: false,
      shareCheckVisible: false,
      reviewVisible: false,
      pay: false,
      percents: 0,
      summaAll: 0,
      summaChecked: 0,
      summaPay: 0,
      diffCheck: false,
      inputTips: false,
      notPaid: 0,
      manualTips: false,
      checkTransaction: true,
      summTransaction: 0,
      items: [],
      tips: 0,
      paidCheckVisible: false,
      enterPhoneVisible: false,
      enterCodeVisible: false,
      code: '',
      dateUpdate: '',
      startAnimation: false,
      subDomen: '',
      tableCode: '',
      waiter: {},
      orderId: '',
      positions_id: [],
      currentDate: '',
      checkedItemsPay: [],
      sumPriceForCheck: null,
      chngeOrderStatus: false,
      blockPay: true,
      preloader: false,
      galochki: [],
      paidOrder: false,
      disabledOrder: [],
      separate: false,
      separateMaintainer: false,
      alienOrderSum: 0,
      discountField: 0,
      procedureDisablingSeparate: false,
      menu: false,
      copyItems: [],
      acquiringCode: null,
      acc_number: null,
      serviceDoNotWorking: false,
      cloudTransactions: 0,
      stopCloudPay: false,
      successfulСhallenge: '',
      addressee: '',
      processCheckOrder: false,
      errorTips: false,
      tips_type: '',
      disabledPayCardBtn: false,
      publicId: null,
      tips_link: '',
      currencyTypeSelected: null,
      paymentProcess: false,
      statusPayment: null,
      messageErrorPayment: null,
      blockPayModal: false,
      stub: {},
    }
  },
  computed: {
    ...mapGetters('orders', [
      'GET_ORDERS',
      'GET_CHECK_TRANSACTION',
      'GET_UPDATE',
    ]),
    ...mapGetters('menuAdmin', ['GET_ADMIN_MENU_TEST']),
    ...mapGetters('customization', ['GET_CUSTOMIZATION']),
    currencyIcon() {
      if (this.currencyTypeSelected?.designation === 'KGS') {
        return `<span class="som-font">${this.currencyTypeSelected?.unicode}</span>`
      }
      return (
        this.currencyTypeSelected?.icon ||
        this.currencyTypeSelected?.unicode ||
        '₽'
      )
    },
    currencyType() {
      return this.currencyTypeSelected?.designation || 'RUB'
    },
    guest() {
      return this.$store.getters['meta/guest']
    },
    selectedGuestId() {
      return this.$store.getters['meta/selectedGuest']
    },
    notPayedItems() {
      if (this.items.length) {
        return this.items.some((i) => i.status === 'NOT_PAID')
      }
      return false
    },
    transactionsSumm: function () {
      if (this.acquiringCode === 'CloudPayments') {
        this.test(this.tips ? ((this.tips / 100) * 24.5).toFixed(2) : 0, 0)
        return ((this.tips / 100) * 24.5).toFixed(2)
      } else {
        let transactionTips = 0
        let transactionMenu = 0

        // transactionTips = (this.tips * 5) / 100 + (this.summaPay * 1) / 100;
        console.log('this TIPS', this.tips)
        transactionTips =
          this.tips > 0
            ? (this.tips * 5) / 100 +
              (this.summaPay * TRANSACTION_PERCENTS) / 100
            : (this.summaPay * TRANSACTION_PERCENTS) / 100
        transactionTips =
          transactionTips < 0.1 ? Math.round(transactionTips) : transactionTips
        this.test(transactionTips, transactionMenu)
        return this.tips == 0
          ? (this.summaPay * TRANSACTION_PERCENTS) / 100 > 0.1
            ? (this.summaPay * TRANSACTION_PERCENTS) / 100
            : Math.round((this.summaPay * TRANSACTION_PERCENTS) / 100)
          : (transactionTips + transactionMenu).toFixed(2)
      }
    },
    discount: function () {
      if (this.code != '') {
        if (this.diffCheck) {
          return Number(
            (
              (this.summaChecked +
                this.tips +
                (this.checkTransaction ? this.summTransaction : 0)) *
              0.03
            ).toFixed(1)
          )
        } else {
          return Number(
            (
              (this.summaAll +
                this.tips +
                (this.checkTransaction ? this.summTransaction : 0)) *
              0.03
            ).toFixed(1)
          )
        }
      } else {
        return 0
      }
    },
    summaPlusDiscount: function () {
      if (this.diffCheck) {
        return Number(
          (
            this.summaChecked +
            this.tips +
            (this.checkTransaction ? this.summTransaction : 0) +
            this.discount +
            this.discountField
          ).toFixed(2)
        )
      } else {
        return Number(
          (
            this.summaAll +
            this.tips +
            (this.checkTransaction ? this.summTransaction : 0) +
            this.discount +
            this.discountField
          ).toFixed(2)
        )
      }
    },
    sumOrderTransaction() {
      return Number(
        this.summaPay + (this.checkTransaction ? this.summTransaction : 0)
      )
    },
    sumOrders() {
      return this.checkTransaction
        ? this.sumOrderTransaction - +this.transactionsSumm
        : this.sumOrderTransaction
    },

    availableTips() {
      const obj = {
        10: false,
        15: false,
        20: false,
        25: false,
      }
      if (196 <= this.sumOrders && this.sumOrders < 246) {
        obj[25] = true
        return obj
      } else if (246 <= this.sumOrders && this.sumOrders <= 326) {
        obj[25] = true
        obj[20] = true
        return obj
      } else if (326 < this.sumOrders && this.sumOrders < 490) {
        obj[25] = true
        obj[20] = true
        obj[15] = true
        return obj
      } else if (this.sumOrders >= 490) {
        obj[25] = true
        obj[20] = true
        obj[15] = true
        obj[10] = true
        return obj
      }

      return obj
    },
  },
  watch: {
    GET_ORDERS(next, prev) {
      this.emptyModal = next.every((product) => product.status === 'PAID')
      this.separate = next.some(
        (product) =>
          product.selected_guest_id &&
          product.selected_guest_id !== this.guest &&
          product.status === 'NOT_PAID'
      )
      this.separateMaintainer = next.some(
        (product) =>
          product.selected_guest_id &&
          product.selected_guest_id === this.guest &&
          product.status === 'NOT_PAID'
      )
      this.items = next.map((product) => {
        return { ...product, active: product.selected_guest_id === this.guest }
      })

      //если у пользователя не выбрана ни одна позиция, отключаем процедуру снятия позиций
      if (!this.separateMaintainer) {
        this.procedureDisablingSeparate = false

        //если у пользователя выбрана позиция и процедура снятия позиций не запущена
        //включаем раздельный счет
      } else if (this.separateMaintainer && !this.procedureDisablingSeparate) {
        const differentGuests = next.map((e) => e.selected_guest_id)
        const duplicates = [...new Set(differentGuests)]

        if (!this.discountField && duplicates.length !== 1) {
          this.diffCheck = true
        }
      }

      if (
        next.reduce(
          (acc, product) => (product.status === 'NOT_PAID' ? acc + 1 : acc),
          0
        ) === 1
      ) {
        this.diffCheck = false
      }

      setTimeout(() => {
        this.recount()
      }, 500)

      if (!this.summaPlusDiscount) {
        this.choicePaymentVisible = false
      }
    },

    GET_UPDATE(next) {
      this.updateInfo()
    },
    // tips() {
    //   if (this.acquiringCode === 'CloudPayments') {
    //     this.cloudCalculateTransactions().then((res) => {
    //       this.cloudTransactions = res
    //     })
    //   }
    // },
  },
  async mounted() {
    const checkingIfRestWorksResponse = await checkingIfRestWorks(
      this.$route.params.shop
    )
    if (!checkingIfRestWorksResponse.result) {
      this.serviceDoNotWorking = true
      return
    }

    this.stub = {
      active: checkingIfRestWorksResponse.stub,
      type: checkingIfRestWorksResponse.stub_type,
    }
    this.subDomen = this.$route.params.shop
    await this.updateInfo()
    // await this.LoadDishList();
    this.recount()
    this.resetTips()

    if (
      !this.waiter ||
      (this.waiter && !this.waiter.card_verification) ||
      this.tips_type !== 'TAPPER'
    ) {
      this.percents = 0
    } else {
      this.checkCurrentTips()
    }
  },

  created() {
    localStorage.removeItem('galochki')
  },

  methods: {
    ...mapActions('cloud', ['CALCULATE_TRANSACTIONS']),
    ...mapActions('authentication', ['CHAANGE_STATUS']),
    ...mapActions('modalPolitic', ['CHANGE_MODAL_POLITIC']),
    ...mapActions('tips', ['FETCH_ENABLED_TIPS']),
    ...mapActions('orders', [
      'UPDATE_ORDERS',
      'CHECK_TRANSACTION',
      'CHANGE_STATUS_CHECK_TRANSACTION',
    ]),

    toggleBlockPayModal() {
      this.blockPayModal = !this.blockPayModal
    },

    async payCloudWithCheck() {
      this.togglePreloader(true)
      const status = await this.checkOrder()
      this.togglePreloader(false)
      return status
    },

    // async cloudCalculateTransactions() {
    //   const res = await this.CALCULATE_TRANSACTIONS({
    //     amount: this.tips,
    //     layoutId: this.waiter.layout_id,
    //   })
    //   return res
    // },

    toggleMenu(open) {
      this.menu = open
    },

    checkCurrentTips(resetTips = false) {
      if (!resetTips) {
        if (this.diffCheck && this.tips === 0) {
          this.percents = null
          return
        }
        if (
          this.processCheckOrder ||
          !this.waiter ||
          !this.waiter.card_verification
        ) {
          return
        }
      }

      if (!this.waiter || !this.waiter.card_verification) return

      // if (!resetTips && this.tips === 0) return;

      if (this.tips_type !== 'TAPPER') {
        this.tips = 0
      } else {
        if (this.sumOrders < 196) {
          this.percents = null
          this.tips = 49
          this.manualTips = false
        } else if (196 <= this.sumOrders && this.sumOrders < 246) {
          this.manualTips = false
          this.selectPercents(25)
        } else if (246 <= this.sumOrders && this.sumOrders <= 326) {
          this.manualTips = false
          this.selectPercents(20)
        } else if (326 < this.sumOrders && this.sumOrders < 490) {
          this.manualTips = false
          this.selectPercents(15)
        } else if (this.sumOrders >= 490) {
          this.manualTips = false
          this.selectPercents(10)
        }
      }
    },

    resetTipsZero() {
      this.percents = 0
      this.tips = 0
    },
    togglePoliticModal() {
      this.CHANGE_MODAL_POLITIC()
    },
    alertModalToggle(val) {
      this.$emit('onHandler', val)
    },
    validTips(data) {
      if (data.length && data > 0) {
        this.tips = parseInt(data)
      }
      if (data.length < 1 || parseInt(data) < 1) {
        this.tips = 0
      }
      this.checkMinTipsError()
      this.manualTips = true
    },
    checkMinTipsError() {
      this.errorTips = +this.tips < 49 && +this.tips !== 0
    },
    togglePreloader(val) {
      this.preloader = val
    },
    changeStatusBtnPay() {
      this.blockPay = true
    },
    async checkOrder() {
      console.log('checkOrder on')
      this.checkMinTipsError()
      if (this.errorTips) return
      this.stopCloudPay = false
      let diffDiscount = false
      this.blockPay = true
      const session = await localStorage.getItem('sessions_id')
      const requestData = {
        table_id: this.$route.params.id,
        domen: this.subDomen,
      }

      // Galochki
      localStorage.removeItem('galochki')
      const checkedArray = this.items.reduce(
        (acc, item) => (item.active ? [...acc, item.id] : acc),
        []
      )
      await localStorage.setItem('galochki', JSON.stringify(checkedArray))
      //--------------

      await axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/order/get`,
          session !== null ? { ...requestData, session: session } : requestData
        )
        .then(async (response) => {
          // Заглушка при оплате
          const checkingIfRestWorksResponse = await checkingIfRestWorks(
            this.$route.params.shop
          )
          if (!checkingIfRestWorksResponse.result) {
            this.serviceDoNotWorking = true
            return
          }

          this.stub = {
            active: checkingIfRestWorksResponse.stub,
            type: checkingIfRestWorksResponse.stub_type,
          }

          if (checkingIfRestWorksResponse.stub === 1) {
            if (checkingIfRestWorksResponse.stub_type === 'PAYMENT') {
              this.blockPayModal = true
              this.blockPay = false
              this.preloader = false
              this.disabledPayCardBtn = false
            }
            return
          }

          this.acquiringCode = response.data.result.acquiringCode
          this.acc_number = response.data.result.acc_number
          this.currencyTypeSelected = response.data.result.currencyTypeSelected

          if (
            response.data.result.waiter &&
            response.data.result.waiter.tips_link
          ) {
            this.tips_link = response.data.result.waiter.tips_link
          }

          // don't touch this field. For checkCurrentTips method.
          this.processCheckOrder = true

          if (!this.discountField) {
            this.galochki = JSON.parse(localStorage.getItem('galochki'))
          }
          const result = response.data.result.ordersElementAll
          const countPaydItems = this.items.reduce(
            (acc, item) => (item.status === 'PAID' ? acc + 1 : acc),
            0
          )
          const tempSummOrders = result.reduce(
            (acc, item) => (item.status === 'PAID' ? acc + 1 : acc),
            0
          )
          this.copyItems = result.map((i) => {
            return { ...i, price: i.price / 100 }
          })
          const hasNewItems = result.length !== this.items.length
          let differentItems = false
          if (!hasNewItems) {
            differentItems = this.items.some(
              (i, index) => i.position_id !== this.copyItems[index].position_id
            )
          }
          const differentWaiter =
            this.waiter?.id !== response?.data?.result?.waiter?.id

          // Проверка на изменение цены позиции
          const oldSummProducts = this.items.reduce(
            (prev, current) => prev + current.price,
            0
          )
          const newSummProducts = response.data.result.ordersElementAll.reduce(
            (prev, current) => prev + current.price / 100,
            0
          )

          if (
            this.discountField !==
              parseInt(response.data.result.discount) / 100 ||
            oldSummProducts !== newSummProducts ||
            differentWaiter
          ) {
            this.stopCloudPay = true
            this.choicePaymentVisible = false
            this.discountField = parseInt(response.data.result.discount) / 100
            this.CHAANGE_STATUS({
              status: true,
              msg: 'Сумма заказа изменилась',
            })
            diffDiscount = true
            console.log('diffDiscount1', diffDiscount)

            this.updateInfo(response).then(() => {
              this.items = this.items.map((product) => {
                if (this.galochki.some((id) => id === product.id)) {
                  return { ...product, active: true }
                }
                return product
              })
              this.recount()
              this.checkCurrentTips(true)
            })

            this.preloader = false
            this.disabledPayCardBtn = false
            this.choicePaymentVisible = false
            setTimeout(() => {
              this.CHAANGE_STATUS({
                status: false,
                msg: '',
              })
            }, 3000)
            return false
          }

          if (
            countPaydItems !== tempSummOrders ||
            hasNewItems ||
            differentItems ||
            differentWaiter
          ) {
            diffDiscount = true
            this.chngeOrderStatus = true
            this.CHAANGE_STATUS({
              status: true,
              // msg: differentItems || hasNewItems ? 'Состав заказа изменился' : 'Сумма заказа изменилась'
              msg: 'Состав заказа изменился',
            })
            this.stopCloudPay = true
            console.log('diffDiscount1', diffDiscount)

            this.updateInfo().then(() => {
              this.items = this.items.map((product) => {
                if (this.galochki.some((id) => id === product.id)) {
                  return { ...product, active: true }
                }
                return product
              })
              this.recount()
            })

            this.preloader = false
            this.disabledPayCardBtn = false

            setTimeout(() => {
              this.CHAANGE_STATUS({
                status: false,
                msg: '',
              })
              this.choicePaymentVisible = false
              this.chngeOrderStatus = false
            }, 3000)
          } else {
            this.blockPay = false
          }
        })
        .catch((e) => {
          this.preloader = false
          this.disabledPayCardBtn = false
          console.log('Ошибка')
          console.log(e)
        })
        .finally(() => {
          setTimeout(() => {
            this.processCheckOrder = false
          }, 500)
        })
      return diffDiscount
    },
    getDate() {
      const date = new Date()
      const seconds =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      const minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const hours = date.getHours()
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      this.currentDate = `${day}.${month}.${year} | ${hours}:${minutes}:${seconds}`
    },
    async shareLink() {
      const locUrl = window.location.href
      const shareData = {
        title: 'Tapper',
        text: 'Сервис для оплаты счета и чаевых в один клик',
        url: locUrl,
      }
      try {
        await navigator.share(shareData)
        console.log('shareData success')
      } catch (err) {
        console.log('share err: ', err)
      }
    },
    LoadScreen(val) {
      this.$emit('LoadScreen', val)
    },
    cloudOnSuccess(val) {
      console.log('cloudOnSuccess', val)
      // this.paidCheckVisible = val
      this.preloader = true
      const link = window.location + `?operation&reference=${val.invoiceId}`
      window.location.href = link
    },
    test(val1, val2) {
      if (this.checkTransaction) {
        const a = val1 + val2
        if (a) {
          this.summTransaction = Number(parseFloat(a).toFixed(2))
        } else {
          this.summTransaction = 0
        }
      } else {
        this.summTransaction = 0
      }
    },
    async updateInfo(response) {
      this.startAnimation = true
      //данные из консоли
      const full = window.location.host
      let vaasheFull = window.location
      let errField = vaasheFull.toString().indexOf('error')
      let errField2 = vaasheFull.toString().indexOf('code')
      let operationField = vaasheFull.toString().indexOf('operation')
      let referenceField = vaasheFull.toString().indexOf('reference')

      if (errField == -1 && errField2 == -1 && operationField != -1) {
        this.paymentProcess = true
        // this.paidCheckVisible = true
      } else {
        localStorage.removeItem('order')
      }
      const parts = full.split('.')
      this.subDomen = this.$route.params.shop

      //получаем данные по get параметру id_table
      const session = await localStorage.getItem('sessions_id')
      let requestArray = {
        table_id: this.$route.params.id,
        domen: this.subDomen,
      }

      if (session != 'null') {
        requestArray.session = session
      }

      //Лютая функцию срочно переписать ее!
      const updateLogic = async (response) => {
        if (!response.data.active) {
          this.serviceDoNotWorking = true
          return
        }

        //Сохраняем начальную общую сумму
        const order = response.data.result.ordersElementAll
        this.separateMaintainer = order
          ? order.some(
              (product) =>
                product.selected_guest_id &&
                product.selected_guest_id === this.guest &&
                product.status === 'NOT_PAID'
            )
          : false
        //если у пользователя не выбрана ни одна позиция, отключаем процедуру снятия позиций

        if (order)
          this.sumPriceForCheck = order.reduce(
            (acc, item) => acc + parseInt(item.price),
            0
          )

        this.tableCode = response.data.result.tableCode
        let newDate = new Date()
        this.LoadScreen(newDate)
        this.waiter = response.data.result.waiter

        this.dishItem = true

        if (!response.data.result.orders) {
          this.emptyModal = true
        }

        if (response.data.result.discount) {
          this.discountField = parseInt(response.data.result.discount) / 100
        } else {
          this.discountField = 0
        }

        if (!this.separateMaintainer && this.discountField !== 0) {
          this.diffCheck = false
        }

        //скрываем "скоро здесь появится ваш заказ"
        if (response.data.result.orders) {
          this.emptyModal = false
          this.orderId = response.data.result.orders[0].order_id
          this.dateUpdate = response.data.date

          this.choicePaymentVisible = false

          // this.CHAANGE_STATUS({
          //   status: true,
          //   msg: 'Сумма заказа изменилась'
          // });
          //
          // setTimeout(() => {
          //   this.CHAANGE_STATUS(
          //     {
          //       status: false,
          //       msg: ''
          //     })
          //
          // }, 3000);

          const guest = await parseInt(this.$cookies.get('guest'))

          this.items = order.map((item) => {
            return {
              ...item,
              price: item.price / 100,
              count: 1,
              active: item.selected_guest_id === guest,
            }
          })

          if (!order.every((product) => product.status === 'PAID')) {
            localStorage.setItem(
              'sessions_id',
              response.data.result.orders[0].sessions_id
            )
          } else {
            this.emptyModal = true
            this.paidOrder = true
            localStorage.removeItem('sessions_id')
            this.notPaid = 0
            setTimeout(() => {
              this.paidOrder = false
            }, 10000)
          }

          this.UPDATE_ORDERS(this.items)
          this.checkedItemsPay = order.reduce((acc, item) => {
            if (
              item.status === 'NOT_PAID' &&
              (item.selected_guest_id === guest || !item.selected_guest_id)
            ) {
              return [...acc, item.id]
            }
            return acc
          }, [])
        }

        if (!response.data.result.waiter) {
          this.percents = 0
        }
        this.recount()
        this.startAnimation = false
      }

      if (response) {
        updateLogic(response)
      } else {
        await axios
          .post(`${process.env.VUE_APP_BASE_URL}/order/get`, requestArray)
          .then(async (response) => {
            console.log('payCard1')
            if (
              response.data.result.waiter &&
              response.data.result.waiter.tips_link
            ) {
              this.tips_link = response.data.result.waiter.tips_link
            }

            this.currencyTypeSelected =
              response.data.result.currencyTypeSelected
            this.tips_type = response.data.result.tips_type
            this.successfulСhallenge = response.data.result.responseTemplate
            this.addressee = response.data.result.recipient
            this.acquiringCode = response.data.result.acquiringCode
            this.acc_number = response.data.result.acc_number
            this.publicId = response.data.result?.public_id

            if (referenceField > 0) {
              let [referenceStr] = vaasheFull.href.match(/[&]reference=[\d]*/)
              let referenceId = referenceStr.replace(/\D/g, '')

              this.paymentProcess = true

              const stopInterval = () => {
                clearInterval(intervalResponse)
              }

              const intervalResponse = setInterval(async () => {
                try {
                  const responseStatus = await this.CHECK_TRANSACTION(
                    referenceId
                  )

                  if (!responseStatus) return

                  this.statusPayment = responseStatus?.data?.status

                  this.CHANGE_STATUS_CHECK_TRANSACTION(
                    responseStatus?.data?.status
                  )

                  if (!this.statusPayment)
                    this.messageErrorPayment = responseStatus?.data?.message
                  stopInterval()
                } catch (error) {
                  console.log('error', error)
                }
              }, 1500)
              // this.CHANGE_STATUS_CHECK_TRANSACTION(false)
            }

            await updateLogic(response)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    async payCard() {
      if (this.diffCheck) {
        this.checkedItemsPay = this.items.reduce((acc, product) => {
          if (
            product.active &&
            product.status === 'NOT_PAID' &&
            (product.selected_guest_id === this.guest ||
              !product.selected_guest_id)
          ) {
            return [...acc, product.id]
          }
          return acc
        }, [])
      } else {
        this.checkedItemsPay = this.items.reduce((acc, product) => {
          if (
            product.status === 'NOT_PAID' &&
            (product.selected_guest_id === this.guest ||
              !product.selected_guest_id)
          ) {
            return [...acc, product.id]
          }
          return acc
        }, [])
      }

      const full = window.location.href
      const requestArray = {
        url: full,
        order_id: this.orderId,
        position_id: this.checkedItemsPay,
        amount: this.summaPay * 100 + this.tips * 100,
        seller_amount: this.tips * 100,
        extra_service_amount: this.summaPay * 100,
        summTransaction: this.summTransaction * 100,
        safePayFlag: 'Y',
        tableCode: this.tableCode,
        waiter: this.waiter.user ? this.waiter.user.id : null,
        guestId: this.guest,
      }
      await axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/best2pay/payorder/`,
          requestArray
        )
        .then((response) => {
          if (response.data.errors) {
            location.reload()
          } else {
            location.href = response.data.result
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setTimeout(() => {
            this.disabledPayCardBtn = false
            this.preloader = false
          }, 5000)
        })
    },
    async payCardWithCallback() {
      this.disabledPayCardBtn = true
      this.checkOrder().then((diff) => {
        if (!diff) {
          this.payCard()
        }
      })
      // setTimeout(() => {
      //   this.disabledPayCardBtn = false
      //   this.preloader = false;
      // }, 5000)
    },

    async showChoicePayment(val) {
      this.checkMinTipsError()
      if (this.errorTips) return
      this.disabledPayCardBtn = true

      // ПРи нажатии на кнопку "Оплатить"
      this.preloader = true
      // don't touch this field. For checkCurrentTips method.
      this.processCheckOrder = true
      // if (val) {
      //   await this.checkOrder();
      // }

      if (this.diffCheck) {
        this.checkedItemsPay = this.items.reduce((acc, product) => {
          if (
            product.active &&
            product.status === 'NOT_PAID' &&
            (product.selected_guest_id === this.guest ||
              !product.selected_guest_id)
          ) {
            return [...acc, product.id]
          }
          return acc
        }, [])
      } else {
        this.checkedItemsPay = this.items.reduce((acc, product) => {
          if (
            product.status === 'NOT_PAID' &&
            (product.selected_guest_id === this.guest ||
              !product.selected_guest_id)
          ) {
            return [...acc, product.id]
          }
          return acc
        }, [])
      }

      // For LocalStorage
      this.getDate()
      const orderData = await localStorage.getItem('order')
      if (orderData && orderData !== null) {
        await localStorage.removeItem('order')
      }

      await this.setOrderDataToLocalStorage()

      // this.choicePaymentVisible = val;
      this.recount()
      //this.preloader = false;

      setTimeout(() => {
        this.processCheckOrder = false
      }, 300)

      this.payCardWithCallback()
    },
    showInputTips() {
      this.inputTips = !this.inputTips
      if (this.tips == '') this.tips = 0
    },
    showCallWaiter(val) {
      if (this.paymentProcess) return
      this.callWaiter = val
    },

    showPayment(val) {
      this.choicePaymentVisible = false
      this.paymentVisible = val
      setTimeout(() => {
        this.paymentVisible = false
        this.paidCheckVisible = true
      }, 2000)
    },
    returnItem(val) {
      this.items = val
    },

    async setOrderDataToLocalStorage() {
      const payedOrders = this.items.reduce((sum, current) => {
        const el = current.status === 'PAID' ? current.price : 0
        return sum + el
      }, 0)

      const AllProductsSumm = this.items.reduce((sum, current) => {
        return sum + current.price
      }, 0)

      const newOrderData = {
        orderId: this.orderId,
        tableCode: this.tableCode,
        waiter: this.waiter,
        summaPlusDiscount: this.summaPlusDiscount,
        diffCheck: this.diffCheck ? 'Частично оплачен' : 'Оплачен',
        date: this.currentDate,
        checkedItemsPay: this.checkedItemsPay,
        summaAll: this.summaAll,
        tips: this.tips,
        summaPay: this.summaPay,
        alienOrderSum: this.alienOrderSum,
        payedOrders: payedOrders,
        status:
          AllProductsSumm -
            payedOrders -
            this.summaPay +
            parseInt(this.discountField) <
          1,
      }
      await localStorage.setItem('order', JSON.stringify(newOrderData))
    },

    showShareCheck(val) {
      this.shareCheckVisible = val
    },

    selectPercents(val) {
      if (
        !this.waiter ||
        this.tips_type === 'DISABLED' ||
        (this.waiter && !this.waiter.card_verification)
      )
        return
      this.percents = val

      if (this.diffCheck && this.summaChecked > 0) {
        this.tips = Math.round((val * this.summaChecked) / 100)
      } else if (
        !this.diffCheck &&
        this.summaAll + parseInt(this.discountField) > 0
      ) {
        this.tips = Math.round(
          (val * (this.summaAll + parseInt(this.discountField))) / 100
        )
      } else {
        this.tips = 0
      }

      this.manualTips = false
      this.errorTips = false
    },

    showReview(val) {
      this.paidCheckVisible = false
      this.reviewVisible = val
      this.recount()
      // this.percents = 0;
    },

    LoadDishList() {
      setTimeout(() => {
        this.dishItem = true
        this.recount()
      }, 5000)
    },

    plus(id) {
      this.items[id].count += 1
      this.recount()
    },
    minus(id) {
      if (this.items[id].count > 1) {
        this.items[id].count -= 1
        this.recount()
      }
    },
    diffCheckFunc() {
      this.diffCheck = !this.diffCheck
      const callback = () => {
        this.recount()
        if (this.waiter && this.waiter.card_verification) {
          this.checkCurrentTips()
        }
      }
      if (this.separateMaintainer) {
        const requestObj = {
          guest: this.guest,
        }
        axios
          .post(
            `${process.env.VUE_APP_BASE_URL}/order/change-off-order-position-all`,
            requestObj
          )
          .then(() => {
            this.procedureDisablingSeparate = true
            callback()
          })
          .catch((err) => {
            console.warn(err)
          })
      } else {
        callback()
      }
    },
    recount() {
      let summa = 0
      this.notPaid = 0
      let self = this
      this.items.forEach((item) => {
        if (item.status === 'NOT_PAID') {
          self.notPaid += 1
        }
        if (
          item.status === 'NOT_PAID' &&
          (item.selected_guest_id === null ||
            item.selected_guest_id === this.guest)
        ) {
          summa = summa + item.price * item.count
        }
      })

      this.summaAll = summa
      let summaChecked = 0

      this.alienOrderSum = this.items.reduce((sum, item) => {
        if (item.selected_guest_id && item.selected_guest_id !== this.guest) {
          return sum + item.price
        }
        return sum
      }, 0)

      let activeItems = this.items.filter((item) => item.active)
      activeItems.forEach(function (item) {
        if (item.status === 'NOT_PAID') {
          summaChecked = summaChecked + item.price * item.count
        }
      })
      this.summaChecked = summaChecked

      if (!this.diffCheck) {
        this.summaPay = this.summaAll + this.discountField
      } else {
        this.summaPay = this.summaChecked
      }

      if (
        this.tips_type === 'TAPPER' &&
        this.waiter &&
        this.waiter.card_verification
      ) {
        if (!this.manualTips && this.summaPay > 0) {
          if (this.selectedGuestId && this.selectedGuestId === this.guest) {
            this.checkCurrentTips()
          } else {
            if (this.percents) {
              this.selectPercents(this.percents)
            } else if (this.tips !== 0) {
              this.tips = 49
              this.manualTips = false
            }
          }
        } else if (!this.manualTips) {
          this.tips = 0
        }
      } else {
        this.tips = 0
      }
    },
    resetTips() {
      this.percents = 0
      if (this.waiter && this.waiter.card_verification) {
        // this.percents = 10;
        if (!this.diffCheck && this.summaPay > 0) {
          this.tips =
            Math.round((this.percents * this.summaPay) / 100) < 49
              ? 49
              : Math.round((this.percents * this.summaPay) / 100)
        } else {
          this.tips = 0
        }
      } else {
        this.percents = 0
        this.tips = 0

        this.checkCurrentTips()
      }
    },

    showPaidCheck(val) {
      this.paymentVisible = false
      this.paidCheckVisible = val
    },

    showEnterPhone(val) {
      if (this.code == '') {
        this.enterPhoneVisible = val
      }
    },

    showEnterCode(val) {
      this.enterPhoneVisible = false
      this.enterCodeVisible = val.showEnterCode
      this.code = val.code
    },

    async checkPosition(payload) {
      const { id, status } = payload
      const guest = parseInt(this.$cookies.get('guest'))
      const data = {
        positions_id: id.toString(),
        guest,
        check_status: !status,
      }

      this.items = this.items.map((item) => {
        if (item.id === id) {
          return { ...item, loading: true }
        }
        return item
      })

      await checkOrderPosition(data).then((res) => {
        const guests_id = res.data.result?.guests_id || null
        this.items = this.items.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              selected_guest_id: guests_id,
              active: guests_id === guest,
              loading: false,
            }
          }
          return item
        })
      })

      this.recount()
      this.checkCurrentTips(true)
    },

    closePaymentProcess(payload = false) {
      this.paymentProcess = false

      if (payload) {
        this.paidCheckVisible = true
      }
    },
  },
}
</script>

<style lang="scss">
.vDemoOrder {
  padding: 16px 16px 50px;
}

.dishList__list {
  & .paid {
    border: 1px solid #e6e7eb;
    color: #cccfd7;

    & .dishList__item-status {
      color: #25c26e;
    }

    & .dish__checkbox-text {
      text-decoration: line-through;

      &:before {
        border-color: #dcdee3;
      }
    }

    & .sum {
      color: #cccfd7;
      text-decoration: line-through;
    }

    & .count {
      opacity: 0.5;
    }
  }
}

.dish__checkbox-text-small {
  font-size: 12px;
  line-height: 14px;
  color: #b4b7bd;
  padding-top: 5px;
}

.divide__check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;

  border: 1px solid #e6e7eb;
  border-radius: 11px;

  background-color: #fff;

  &-label {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #494c55;
  }

  &-switch {
    position: relative;
    display: inline-block;
    min-width: 48px;
    height: 24px;

    & input {
      display: none;

      &:checked + .slider {
        background-color: #6764ff;
      }

      &:disabled + .slider {
        background-color: #e6e7eb;
      }

      &:checked + .slider::before {
        transform: translateX(23px);
      }
    }
  }

  & .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e6e7eb;
    transition: 0.4s;
    border-radius: 50px;

    &::before {
      position: absolute;
      content: '';
      width: 19px;
      height: 19px;
      left: 3px;
      bottom: 3px;
      background-color: #fff;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}

.payment {
  margin-bottom: 49px;

  & .payment-btn:disabled {
    color: #020000;
    letter-spacing: 0.03em;
    background: #edeef1;
  }

  & .share-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 6px;

    color: #343d5d;
    background: #edeef1;

    &__icon {
      display: flex;
      margin-left: 10px;
    }
  }

  &__conditions {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #80869a;

    &-check {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:checked + .payment__conditions-text::before {
        background-image: url('~@/assets/images/paid_check.d789c5ba.svg');
      }

      &:checked + .payment__conditions-text-VTB::before {
        background-image: url('~@/assets/images/paid_check-VTB.svg');
      }
    }

    &-text {
      display: inline-flex;
      align-items: center;
      user-select: none;

      &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1.5px solid #dcdee3;
        border-radius: 0.4em;
        margin-right: 17px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px;
      }

      & a {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #80869a;
      }
    }
    &-text-VTB {
      display: inline-flex;
      align-items: center;
      user-select: none;

      &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1.5px solid #dcdee3;
        border-radius: 0.4em;
        margin-right: 17px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px;
      }

      & a {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #80869a;
      }
    }
  }
}

.menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 62px;
  border-top: 1px solid #cccfd7;
  background-color: #fff;
  z-index: 3;

  & .callWaiter {
    position: absolute;
    left: 50%;
    top: -50%;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    border-radius: 100px;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #cccfd7;
    border-right: 1px solid #cccfd7;
    background-color: #fff;
    transform: translateX(-50%) rotate(45deg);

    & img {
      transform: rotate(-45deg);
    }
  }

  & .menuOpen {
    position: absolute;
    top: 16px;
    left: 65px;
    stroke: #cccfd7;
  }

  & .creditCardOpen {
    position: absolute;
    top: 14px;
    right: 65px;
    stroke: #cccfd7;
  }

  .openActive {
    stroke: #343d5d;
    &__VTB {
      stroke: #4c7dd5;
    }
  }

  .priceCard {
    position: absolute;
    top: 5px;
    right: 35px;
    border: 1px solid #343d5d;
    background: #343d5d;
    color: #fff;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 500;
    padding: 3px 6px;
    &__VTB {
      border: 1px solid #4c7dd5;
      background: #4c7dd5;
    }
  }
}

.callWaiter__text {
  left: 50%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  transform: translateX(-50%);
  position: fixed;
  bottom: 110px;

  & span {
    margin-bottom: 13px;
  }
}

.main-btn {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 12px;

  font-family: $base-font;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.03em;
  color: #fff;

  border-radius: 12px;
  border: none;

  outline: none;
  cursor: pointer;

  background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);

  &__VTB {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 12px;

    font-family: $base-font;
    text-align: center;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #fff;

    border-radius: 12px;
    border: none;

    outline: none;
    cursor: pointer;

    background: linear-gradient(223.61deg, #4c7dd5 99.99%, #413dff 100%);
  }
}

.main-btn:disabled {
  color: #000000;
  background: #edeef1;
}

.refresh_icon {
  width: 30px;
  height: 30px;
}

.refresh_icon_time {
  font-weight: 100;
  color: #c8c9cc;
}

.refresh_icon.loading {
  animation: loadingTime 1s linear infinite;
}

@keyframes loadingTime {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tips__waiter img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.custom-input input::placeholder {
  color: #000;
}

.dishList__spinner {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.callWaiter__text {
  left: 50%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  transform: translateX(-50%);
  position: fixed;
  bottom: 110px;

  & span {
    margin-bottom: 13px;
  }
}

.paymentMethods {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  &__img {
    margin-right: 9px;
  }
  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #343d5d;
    margin-right: 9px;
  }
}

.newYear {
  &__img {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 58px;
    margin-bottom: 12px;

    &-wrap {
      position: relative;
      z-index: 1;
      display: flex;

      img {
        width: 100%;
      }
    }

    &-center {
      width: 40px;
      height: 40px;
    }

    &-left {
      width: 87px;
      height: 57px;
    }

    &-right {
      width: 50px;
      height: 48px;
    }
  }

  &__img-line {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 6px;

    background-image: url(~@/assets/images/NY_heading-line.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 47%;
  }
}
</style>
