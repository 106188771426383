<template>
  <div class="dishList">
    <div
      v-if="dishItem && notPaid != 0 && !discountField"
      class="divide__check"
    >
      <span class="divide__check-label">Разделить счёт</span>

      <label
        class="divide__check-switch"
        :class="{ 'divide__check-switch__VTB': theme.value === 'VTB' }"
      >
        <input
          :checked="diffCheck"
          type="checkbox"
          :disabled="notPaid == 1"
          @input="$emit('diffClick')"
        >

        <span class="slider" />
      </label>
    </div>

    <ul class="dishList__list">
      <li
        v-for="item in items"
        :key="item.id"
      >
        <OrderDishItem
          :item="item"
          :diff-check="diffCheck"
          :currency-icon="currencyIcon"
          @checkPosition="checkPosition"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import OrderDishItem from '@/components/order/OrderDishItem'

export default {
  name: 'OrderDishList',
  components: { OrderDishItem },
  inject: ['theme'],
  props: {
    dishItem: {
      type: Boolean,
      default: false,
      required: true,
    },

    diffCheck: {
      type: Boolean,
      default: false,
      required: true,
    },

    notPaid: {
      type: [String, Number],
      required: true,
      default: 0,
    },

    items: {
      type: Array,
      required: true,
      default: () => [],
    },

    discountField: {
      type: Number,
      default: 0,
    },

    currencyIcon: {
      type: String,
      default: '-',
    },
  },
  emits: ['checkPosition', 'diffClick'],

  data() {
    return {
      list: [...this.items],
    }
  },

  methods: {
    checkPosition(data) {
      this.$emit('checkPosition', data)
    },
  },
}
</script>

<style scoped lang="scss">
.divide__check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;

  border: 1px solid #e6e7eb;
  border-radius: 11px;

  background-color: #fff;

  &-label {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #494c55;
  }

  &-switch {
    position: relative;
    display: inline-block;
    min-width: 48px;
    height: 24px;

    & input {
      display: none;

      &:checked + .slider {
        background-color: #6764ff;
      }

      &:disabled + .slider {
        background-color: #e6e7eb;
      }

      &:checked + .slider::before {
        transform: translateX(23px);
      }
    }
  }
  &-switch__VTB {
    position: relative;
    display: inline-block;
    min-width: 48px;
    height: 24px;

    & input {
      display: none;

      &:checked + .slider {
        background-color: #4c7dd5;
      }

      &:disabled + .slider {
        background-color: #e6e7eb;
      }

      &:checked + .slider::before {
        transform: translateX(23px);
      }
    }
  }

  & .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e6e7eb;
    transition: 0.4s;
    border-radius: 50px;

    &::before {
      position: absolute;
      content: '';
      width: 19px;
      height: 19px;
      left: 3px;
      bottom: 3px;
      background-color: #fff;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}
</style>
