<template>
  <div
    v-if="error"
    class="vLimitModal"
  >
    <div
      class="vLimitModal__overlay"
      @click="closeNotification"
    />
    <div
      class="vLimitModal__content"
      v-html="error.message"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'VSocketNotification',
  props: {
    timeOut: {
      default: 3000,
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      error: null,
      timeoutId: null,
    }
  },

  computed: {
    ...mapGetters('orders', ['GET_ERRORS']),
  },

  watch: {
    GET_ERRORS(next, prev) {
      this.error = next[0]
      if (next.length) {
        clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(() => {
          this.updateErrors(next.slice(1))
        }, this.timeOut)
      }
    },
  },

  methods: {
    closeNotification() {
      this.error = null
      this.updateErrors(this.GET_ERRORS.slice(1))
    },
    updateErrors(errors) {
      this.$store.commit('orders/SET_ERRORS', errors)
    },
  },
}
</script>
<style lang="scss">
.vLimitModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(220, 222, 227, 0.5);
    backdrop-filter: blur(7px);
  }
  &__content {
    background: #fff;
    border-radius: 12px;
    padding: 24px 20px;
    color: #010d35;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    font-family: 'Commissioner';
    position: relative;
    z-index: 2;
    text-align: center;
  }
}
</style>
