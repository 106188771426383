<template>
  <button
    class="payment-btn main-btn"
    type="button"
    :disabled="disabled || disabledProp || errorTips"
    @click="check"
  >
    Оплатить
  </button>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'CloudPayments',
  props: {
    summaPay: {
      type: Number,
      required: true,
      default: 0,
    },
    disabledProp: {
      type: Boolean,
      required: false,
      default: false,
    },
    acquiringCode: {
      type: String,
      required: true,
      default: '',
    },
    accNumber: {
      type: String,
      required: true,
      default: '',
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    dataRequest: {
      type: Object,
      required: true,
      default: () => {},
    },
    checkedItemsPay: {
      type: Object,
      required: true,
    },
    diffCheck: {
      type: Boolean,
      required: true,
      default: false,
    },
    orderId: {
      type: String,
      required: true,
      default: '',
    },
    summTransaction: {
      type: Number,
      required: true,
      default: 0,
    },
    sellerAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    tableCode: {
      type: String,
      required: true,
      default: '0',
    },
    feeAmount: {
      type: Boolean,
    },
    waiter: {
      type: String,
      default: '',
    },
    guestId: {
      type: Number,
      required: true,
    },
    payCloudWithCheck: {
      type: Function,
      required: true,
    },
    stopCloudPay: {
      type: Boolean,
      default: false,
    },
    errorTips: {
      type: Boolean,
      default: false,
    },
    publicId: {
      type: String,
      default: '',
    },
    tips: {
      type: String || Number,
      default: null,
    },
    currencyType: {
      type: String,
      default: 'RUB',
    },
  },
  emits: ['cloudOnSuccess', 'clickOrder'],
  data() {
    return {
      disabled: false,
    }
  },
  methods: {
    ...mapActions('cloud', ['PAY_CLOUD']),
    disabledButton(value) {
      console.log('disabledButton', value)
      this.disabled = value
    },
    async check() {
      this.disabled = true

      this.$emit('clickOrder')

      const status = await this.payCloudWithCheck()

      if (this.stopCloudPay || status) {
        this.disabled = false
        return
      }

      await this.pay()
    },
    async pay() {
      let itemsId = []
      let dataRequest = {
        order_id: this.orderId,
        safePayFlag: 'Y',
        amount: this.summaPay * 100 + this.sellerAmount * 100,
        summTransaction: this.feeAmount ? this.summTransaction * 100 : 0,
        extra_service_amount: this.summaPay * 100,
        seller_amount: this.sellerAmount * 100,
        tableCode: this.tableCode,
        url: window.location.href,
        waiter: this.waiter ? parseInt(this.waiter.id) : null,
        guestId: this.guestId,
        feeAmount: this.feeAmount,
      }

      if (this.diffCheck) {
        this.items.forEach((i) => {
          if (i.active && i.status_pay !== 1) {
            itemsId.push(i.id)
          }
        })
      } else {
        this.items.forEach((i) => {
          if (i.status_pay !== 1) {
            itemsId.push(i.id)
          }
        })
      }

      dataRequest.position_id = itemsId

      console.log('dataRequest', dataRequest)
      const res = await this.PAY_CLOUD(dataRequest)
      console.log('res', res)
      if (res?.success) {
        console.log('PAY')
        this.disabledButton(true)
        var widget = new cp.CloudPayments()
        const self = this
        widget.pay(
          'charge', // или 'charge'
          {
            //options
            publicId: this.publicId, //id из личного кабинета
            description: this.tips
              ? 'При оплате будут отдельно списаны суммы за заказ и чаевые, и вы получите два смс-сообщения 😃'
              : '', //назначение
            amount: this.summaPay, //сумма
            currency: this.currencyType, //валюта
            accountId: res.result, //идентификатор плательщика (необязательно)
            invoiceId: res.result, //номер заказа  (необязательно)
            email: '', //email плательщика (необязательно)
            skin: '', //дизайн виджета (необязательно)
            // data: {
            //   myProp: "myProp value",
            // },
          },
          {
            onSuccess: function (options) {
              // success
              //действие при успешной оплате
              console.log('CloudPayments onSuccess')
              this.disabled = false
              self.$emit('cloudOnSuccess', options)
              console.log('options', options)
              self.disabledButton(false)
            },
            onFail: function (reason, options) {
              // fail
              //действие при неуспешной оплате
              console.log('CloudPayments onFail')
              this.disabled = false
              console.log('reason', reason)
              console.log('options', options)
              self.disabledButton(false)
            },
            onComplete: function (paymentResult, options) {
              //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
              //например вызов вашей аналитики Facebook Pixel
              console.log('CloudPayments onComplete')
              this.disabled = false
              console.log('onComplete paymentResult', paymentResult)
              console.log('onComplete options', options)
              self.disabledButton(false)
            },
          }
        )
      }
    },
  },
}
</script>
<style lang="scss"></style>
