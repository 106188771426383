<template>
  <div
    class="modal__overlay modal__overlay--payment"
    @click="close"
  />
  <div class="payment-process">
    <div class="container">
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="statusPayment === false"
          class="payment-process__close"
          @click="close"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_7098_209310)">
              <rect
                width="24"
                height="24"
                rx="12"
                fill="#F7F8FA"
              />
              <path
                d="M16 8L8 16M8 8L16 16L8 8Z"
                stroke="#EC4E4E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <rect
              x="0.5"
              y="0.5"
              width="23"
              height="23"
              rx="11.5"
              stroke="#EDEEF1"
            />
            <defs>
              <clipPath id="clip0_7098_209310">
                <rect
                  width="24"
                  height="24"
                  rx="12"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </transition>

      <div class="payment-process__gif">
        <transition
          name="fade"
          mode="out-in"
        >
          <img
            v-if="statusPayment"
            :key="statusPayment"
            src="@/assets/images/paymentProcess/yes.png"
            alt="payment"
          >
          <img
            v-else-if="statusPayment === false"
            :key="statusPayment"
            src="@/assets/images/paymentProcess/no.png"
            alt="payment"
          >
          <img
            v-else
            :key="statusPayment"
            src="@/assets/images/paymentProcess/payment_gif.gif"
            alt="payment"
          >
        </transition>
      </div>

      <transition
        name="fade"
        mode="out-in"
      >
        <div
          :key="statusPayment"
          class="payment-process__status"
        >
          {{ statusTitle }}
        </div>
      </transition>

      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="statusPayment"
          :key="statusPayment"
          class="payment-process__text"
        />

        <div
          v-else-if="statusPayment === false"
          :key="statusPayment"
          class="payment-process__text"
        >
          {{ errorMessage }}
        </div>

        <div
          v-else
          :key="statusPayment"
          class="payment-process__text"
        >
          Пожалуйста, подождите..
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentProcess',
  components: {},
  props: {
    status: {
      type: Boolean,
      default: null,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  data() {
    return {
      statusPayment: null,
    }
  },
  computed: {
    statusTitle() {
      return this.statusPayment
        ? 'Оплата прошла успешно!'
        : this.statusPayment === false
        ? 'Оплата не прошла'
        : 'Производится оплата'
    },
  },
  watch: {
    async status(newValue) {
      setTimeout(() => {
        this.statusPayment = newValue
      }, 1500)

      if (newValue) {
        setTimeout(() => {
          this.$emit('close', true)
        }, 4500)
      }
    },
  },
  methods: {
    close() {
      if (this.statusPayment === false) this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.payment-process {
  position: fixed;
  bottom: 45px;

  padding-top: 16px;
  width: 100%;
  min-height: 254px;

  background-color: #fff;

  z-index: 2;
  border-radius: 12px 12px 0 0;

  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px 32px 50px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 16px;
    cursor: pointer;
  }

  &__gif {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
  }

  &__status {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
  }

  &__text {
    max-width: 350px;
    margin-bottom: 32px;
    text-align: center;
    font-weight: 400;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
