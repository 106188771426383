<template>
  <div class="menuDishes">
    <div
      ref="menuDishes__name"
      class="menuDishes__name"
    >
      <template
        v-for="item in items"
        :key="item.id"
      >
        <a
          v-if="item.products.length && item.hidden == 0"
          v-scroll-to="{
            el: '#' + item.label,
            offset: -150,
            duration: 250,
            onStart: activeMenu,
          }"
          href="#"
          class="menuDishes__search-dishes"
          :class="{ active: activeElement == item.label }"
          @click="scrollLeft"
        >
          {{
            item.our_name && item.our_name !== '-' && item.our_name !== 'null'
              ? item.our_name
              : item.name
          }}
        </a>
      </template>
    </div>

    <template
      v-for="dishCell in items"
      :key="dishCell.id"
    >
      <div v-if="dishCell.hidden == 0">
        <div
          v-if="dishCell.products.length"
          :id="dishCell.label"
          class="menuDishes__type-dishes"
        >
          <div class="menuDishes__header">
            {{
              dishCell.our_name &&
                dishCell.our_name !== '-' &&
                dishCell.our_name !== 'null'
                ? dishCell.our_name
                : dishCell.name
            }}
          </div>
          <div class="menuDishes__link">
            <template
              v-for="product in dishCell.products"
              :key="product.id"
            >
              <div
                v-if="product.hidden == 0"
                class="menuDishes__cell"
              >
                <div
                  v-if="product.product_picture"
                  class="menuDishes__image"
                >
                  <img
                    :src="product.product_picture.versions.optimized"
                    :alt="product.name"
                  >
                </div>
                <div class="menuDishes__name-dish">
                  {{
                    product.our_name &&
                      product.our_name !== 'null' &&
                      product.our_name !== '-'
                      ? product.our_name
                      : product.name
                  }}
                </div>
                <div class="menuDishes__priceWt">
                  <div class="menuDishes__price">
                    {{
                      product.price > 0 ? product.price / 100 : product.price
                    }}&#8381;
                  </div>

                  <!--                  ГРАММЫ-->

                  <!--                  <div-->
                  <!--                    v-if="product.calculus"-->
                  <!--                    class="menuDishes__wt"-->
                  <!--                  >-->
                  <!--                    {{ product.calculus ? product.calculus : '-' }}-->
                  <!--                    {{ product.type_of_calculus && product.type_of_calculus }}-->
                  <!--                  </div>-->
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import scrollama from 'scrollama'
import { mapGetters } from 'vuex'

export default {
  name: 'VMenuDishes',
  data() {
    return {
      activeElement: 'hotter',
      items: [
        // {id:0, name: "Горячее", label: "hotter"},
        // {id:1, name: "Закуски", label: "snacks"},
        // {id:2, name: "Десерты", label: "desserts"},
        // {id:3, name: "Салаты", label: "salads"},
        // {id:4, name: "Пицца", label: "pizza"},
        // {id:5, name: "Азия", label: "asia"},
      ],
    }
  },

  computed: {
    ...mapGetters('menuAdmin', ['GET_ADMIN_MENU_TEST']),
  },

  async mounted() {
    await this.GET_ADMIN_MENU_TEST.forEach((i) => {
      this.items.push({
        ...i,
        label: i.name.replace(/[\s.,%]/g, ''),
      })
    })
    this.activeElement = this.items.find((e) => e.hidden === 0).label

    scrollama()
      .setup({
        step: '.menuDishes__type-dishes',
      })
      .onStepEnter((response) => {
        // console.log(response['index'])
        const links = document.querySelectorAll('.menuDishes__search-dishes')
        for (let i = 0; i < links.length; i++) {
          links[i].classList.remove('active')
          this.$refs.menuDishes__name.scrollTo(leftElem, 0)
        }
        links[response['index']].classList.add('active')
        let leftElem = links[response['index']].offsetLeft
        // let widthElem = links[response['index']].offsetWidth
        // let position = leftElem + widthElem
        this.$refs.menuDishes__name.scrollTo(leftElem, 0)
        // { element, index, direction }
      })
      .onStepExit((response) => {
        // console.log(response['index'])
        // { element, index, direction }
      })
  },
  methods: {
    activeMenu(val) {
      // console.log('', val)

      this.activeElement = val.name
    },
    scrollLeft(e) {
      this.$refs.menuDishes__name.scrollLeft +=
        e.target.getBoundingClientRect().left -
        this.$refs.menuDishes__name.getBoundingClientRect().left
    },
  },
}
</script>

<style lang="scss">
.myOrder-menu {
  position: sticky;
  top: 0;
  z-index: 1;
  //padding-top: 16px;
  padding-top: 16px;
  margin-bottom: 0 !important;
  background: white;
}
.menuDishes {
  &__name {
    display: flex;
    //justify-content: space-between;
    align-items: center;
    padding: 48px 0 20px 0;
    position: sticky;
    top: 40px;
    z-index: 1;
    background: white;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__search-dishes {
    white-space: nowrap;
    font-weight: 400;
    font-size: 16px;
    color: #80869a;
    //width: 119px!important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  .active {
    background: #ffffff;
    border: 1px solid #e7e8ec;
    border-radius: 11px;
    color: #241c2d !important;
    padding: 0 29px;
  }
  &__type-dishes {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 37px;
    }
  }
  &__link {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &__header {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }
  &__cell {
    //width: calc(50% - 6.5px);
    max-width: 265px;
    //width: calc(33.3% - 11px);
    padding: 16px 16px 35px 16px;
    border: 1px solid #e7e8ec;
    border-radius: 12px;
    margin: 0 11px 10px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (max-width: 600px) {
      padding: 10px 10px 35px 10px;
    }
  }
  &__image {
    padding-bottom: 10px;
    img {
      width: 100%;
      height: 188px;
      object-fit: cover;
      border-radius: 12px;
      @media screen and (max-width: 600px) {
        height: 146px;
      }
    }
    & + .menuDishes__name-dish {
      margin-bottom: auto;
      margin-top: 0;
    }
  }
  &__name-dish {
    font-size: 16px;
    line-height: 20px;

    margin-top: auto;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &__priceWt {
    display: flex;
    align-items: flex-end;
    margin-top: 5px;
    position: absolute;
    bottom: 10px;
  }
  &__price {
    font-weight: 500;
    font-size: 16px;
    margin-right: 5px;
  }
  &__wt {
    font-weight: 400;
    font-size: 12px;
    color: #80869a;
  }
}
@media screen and (max-width: 1285px) {
  .menuDishes {
    &__link {
      max-width: 1000px;
      //margin: 0 auto;
    }
    &__cell {
      width: 30%;
    }
  }
}
@media screen and (max-width: 650px) {
  .menuDishes {
    &__link {
      max-width: 100%;
      margin: 0 0;
    }
    &__cell {
      width: calc(50% - 11px);
    }
  }
}
</style>
