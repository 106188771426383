<template>
  <div
    v-if="showBtn"
    class="VApplePay"
    :class="{ active: dis }"
    @click="applePayButtonClicked"
  >
    <img
      src="~@/assets/images/apple_pay_logo.svg"
      alt=""
    >
    <img
      src="~@/assets/images/mir.png"
      class="apple-mir"
    >
    <img
      src="~@/assets/images/ApplePay.gif"
      class="apple-preloader"
    >
    <vLimitModal
      v-if="limit"
      @toggleLimit="toggleLimit"
    />
  </div>
</template>

<script>
import vLimitModal from '@/components/order/v-limit-modal'
import axios from 'axios'
export default {
  name: 'VApplePay',
  components: {
    vLimitModal,
  },
  props: {
    sum: {
      type: Number,
      required: false,
      default: 0,
    },
    sellerAmount: {
      type: Number,
      required: false,
      default: 0,
    },
    summTransaction: {
      type: Number,
      required: false,
      default: 0,
    },
    summaPay: {
      type: Number,
      required: true,
    },
    checkedItemsPay: {
      type: Array,
      required: true,
    },
    returnLink: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['onHandler', 'getToken', 'showAlertModal'],
  data() {
    return {
      dis: false,
      amount: this.sum,
      fee: 0,
      sector: 3032,
      resultType: 'Xml',
      preauth: 'Y',
      currency: 'RUB',
      reference: 'N67589',
      language: 'RUS',
      showBtn: false,
      registerID: '',
      transactionID: '',
      returnLink2: false,
    }
  },
  watch: {
    returnLink() {
      this.returnLink2 = true
    },
  },
  mounted() {
    //1 ApplePay: Отображение кнопки, если браузер поддерживает ApplePay
    if (window.ApplePaySession) {
      if (ApplePaySession.canMakePayments) {
        this.showApplePayButton()
      }
    }
  },
  methods: {
    onHandler() {
      this.$emit('onHandler')
    },
    async setToken(val) {
      await this.$emit('getToken', val)
    },
    async getApplePaySession(url, session) {
      console.log('getApplePaySession')
      const data = {
        url: url,
      }
      var config = {
        headers: { 'Content-Type': 'text/xml' },
      }
      console.log('data', data)

      return new Promise(function (resolve) {
        axios
          .post(`${process.env.VUE_APP_BASE_URL}/best2pay/applepay`, data)
          .then((response) => {
            console.log('response', response)
            console.log('response1', 123)

            const one = response.data.split('<tokenBody>')
            const two = one[1].split('</tokenBody>')
            const tokenBody = JSON.parse(two[0])
            console.log('tokenBody', tokenBody)

            resolve(tokenBody)
          })
      })
    },

    //0 ApplePay: Отображение кнопки, если браузер поддерживает ApplePay
    showApplePayButton() {
      HTMLCollection.prototype[Symbol.iterator] =
        Array.prototype[Symbol.iterator]
      this.showBtn = true
    },

    //2 ApplePay: Клик по кнопке
    async applePayButtonClicked() {
      this.dis = true
      //зарегим заказ
      const full = window.location.href
      let extra_service_amount =
        (this.sum - this.sellerAmount - this.summTransaction) * 100
      let requestArray = {
        url: full,
        position_id: this.checkedItemsPay,
        amount: this.summaPay * 100 + this.sellerAmount * 100,
        seller_amount: this.sellerAmount * 100,
        extra_service_amount: this.summaPay * 100,
        summTransaction: this.summTransaction * 100,
        safePayFlag: 'Y',
        applePayStatus: 'register',
      }
      console.log('requestArray123', requestArray)
      const paymentRequest = {
        requiredShippingContactFields: ['email', 'phone'],
        countryCode: 'RU',
        currencyCode: this.currency,
        supportedNetworks: ['visa', 'masterCard', 'mir'],
        merchantCapabilities: ['supports3DS'],
        lineItems: [
          {
            type: 'final',
            label: 'Сумма платежа',
            //Для wallet указывается в рублях amount: amount/100
            amount: this.amount,
          },
          {
            type: 'final',
            label: 'Комиссия',
            //Для wallet указывается в рублях
            amount: 0,
          },
        ],
        total: {
          label: 'Сумма с комиссией',
          //Для wallet указывается в рублях
          amount: this.amount + this.fee,
        },
      }
      const session = new ApplePaySession(1, paymentRequest)
      session.onvalidatemerchant = (event) => {
        this.getApplePaySession(event.validationURL, session).then(function (
          response
        ) {
          console.log('session123', session)
          console.log('res', response)
          session.completeMerchantValidation(response)
        })
      }

      await axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/best2pay/payorder/`,
          requestArray
        )
        .then((response) => {
          if (response.data.result === 'wait') {
            this.$emit('showAlertModal')
            console.log('WAIT')
          } else {
            this.registerID = response.data.result.id
            this.transactionID = response.data.result.transactionID
            setTimeout(() => {
              this.dis = false
            }, 2000)
            let self = this
            session.onpaymentauthorized = async (event) => {
              console.log('event.payment.token', event.payment.token)
              let paymentToken = JSON.stringify(event.payment.token)
              let paymentToken2 = JSON.stringify(
                event.payment.token.paymentData
              )
              let shippingContact = JSON.stringify(
                event.payment.shippingContact.emailAddress
              )

              self.setToken({
                token: paymentToken,
                registerID: this.registerID,
                shippingContact: shippingContact,
                transactionID: this.transactionID,
                payment: 'apple',
              })
              let status

              if (this.returnLink2) {
                status = ApplePaySession.STATUS_SUCCESS
                session.completePayment(status)
              }
            }
            session.begin()
            console.log('responseID', response)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
<style lang="scss">
.VApplePay {
  background: #fff;
  border: 1px solid #e6e7eb;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 12px;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.03em;
  color: #fff;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #000;
  .apple-preloader {
    display: none;
    position: absolute;
    left: calc(50% + 65px);
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &.active {
    opacity: 0.5;
    cursor: no-drop;
    .apple-preloader {
      display: block;
    }
  }
  & img {
    vertical-align: middle;
    margin-right: 8px;
  }
}
</style>
