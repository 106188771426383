import axios from 'axios'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

export async function orderGet(data) {
  return await axios
    .post(`${process.env.VUE_APP_BASE_URL}/order/get`, data)
    .then((response) => {
      if (!data.guest && response.data?.result?.guest) {
        const guest = response.data.result.guest.toString()
        cookies.set('guest', guest)
      }
      console.log(response.data)
      const session = response.data.result.hasOwnProperty('orders')
        ? response.data.result?.orders[0].sessions_id.toString()
        : null

      cookies.set('session', session)

      return response.data.result
    })
    .catch((e) => {
      if (e) {
        console.log(e)
      }
    })
}

export async function checkOrderPosition(data) {
  return await axios.post(
    `${process.env.VUE_APP_BASE_URL}/order/change-order-position`,
    data
  )
}
