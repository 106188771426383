<template>
  <div class="tips">
    <div v-if="theme.value === 'VTB' && waiter.card_verification">
      <img src="@/assets/images/tips-VTB.svg">
    </div>

    <span
      v-if="
        waiter.card_verification &&
          tips_type.value === 'TAPPER' &&
          theme.value === 'TAPPER'
      "
      class="tips__heading"
    >
      <span class="tips__heading-icon">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 6H6C4.34315 6 3 7.34315 3 9V12H11V6Z"
            fill="#EC4E4E"
          />
          <path
            d="M3 14V18C3 19.6569 4.34315 21 6 21H11V14H3Z"
            fill="#EC4E4E"
          />
          <path
            d="M13 21H18C19.6569 21 21 19.6569 21 18V14H13V21Z"
            fill="#EC4E4E"
          />
          <path
            d="M21 12H13V6H18C19.6569 6 21 7.34315 21 9V12Z"
            fill="#EC4E4E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.4 8C7.52223 8 6 6.47777 6 4.6C6 3.16406 7.16406 2 8.6 2H8.66667C10.007 2 11.2051 2.60849 12 3.56428C12.7949 2.60849 13.993 2 15.3333 2H15.4C16.8359 2 18 3.16406 18 4.6C18 6.47777 16.4778 8 14.6 8H9.4ZM9.4 6H10.9764C10.8146 4.86924 9.84216 4 8.66667 4H8.6C8.26863 4 8 4.26863 8 4.6C8 5.3732 8.6268 6 9.4 6ZM14.6 6H13.0236C13.1854 4.86924 14.1578 4 15.3333 4H15.4C15.7314 4 16 4.26863 16 4.6C16 5.3732 15.3732 6 14.6 6Z"
            fill="#25C26E"
          />
        </svg>
      </span>
      Чаевые
    </span>

    <div class="tips__waiter">
      <img
        v-if="waiter.avatar && waiter.avatar !== null"
        :src="waiter.avatar"
        alt="Фото официанта"
      >

      <div class="tips__waiter-info">
        <span>Официант</span>

        <span>{{
          waiter?.display_name || waiter?.display_name.length
            ? waiter.display_name
            : waiter?.name
        }}</span>
      </div>
    </div>

    <div
      v-if="waiter.card_verification && tips_type.value === 'TAPPER'"
      class="tips__sum"
    >
      <!-- <span>{{ tips }}</span>  -->
      <span class="custom-input">
        <input
          v-maska="'######'"
          :value="tipsValue"
          placeholder="0"
          @input="$emit('validTips', $event.target.value)"
          @blur="$emit('checkMinTipsError')"
        >
        <div class="tips__sum-cursor" />
        <span v-html="currencyIcon" />
      </span>
      <img
        v-if="inputTips"
        class="close-input"
        src="@/assets/images/close_icon.svg"
        alt="Закрыть"
        @click="$emit('showInputTips')"
      >
      <button
        class="tips__sum-btn"
        type="reset"
        @click="$emit('resetTipsZero')"
      >
        <img
          src="@/assets/images/close_icon.svg"
          alt="Закрыть"
        >
      </button>
    </div>

    <div
      v-show="errorTips && tipsValue < 49 && tipsValue !== 0"
      class="tips__error"
    >
      Минимальная сумма чаевых 49 ₽
    </div>

    <div
      v-if="waiter.card_verification && tips_type.value === 'TAPPER'"
      class="tips__percents"
    >
      <ul
        v-if="theme.value === 'VTB'"
        class="tips__percents-list"
      >
        <li
          class="tips__list-item__VTB"
          :class="{ active__VTB: percents === 0 && !manualTips }"
          @click="$emit('selectPercents', 0)"
        >
          0%
        </li>
        <li
          class="tips__list-item__VTB"
          :class="{
            active__VTB: percents === 10 && !manualTips,
            disabled: !availableTips[10],
          }"
          @click="$emit('selectPercents', 10)"
        >
          10%
        </li>
        <li
          class="tips__list-item__VTB"
          :class="{
            active__VTB: percents === 15 && !manualTips,
            disabled: !availableTips[15],
          }"
          @click="$emit('selectPercents', 15)"
        >
          15%
        </li>
        <li
          class="tips__list-item__VTB"
          :class="{
            active__VTB: percents === 20 && !manualTips,
            disabled: !availableTips[20],
          }"
          @click="$emit('selectPercents', 20)"
        >
          20%
        </li>
        <li
          class="tips__list-item__VTB"
          :class="{
            active__VTB: percents === 25 && !manualTips,
            disabled: !availableTips[25],
          }"
          @click="$emit('selectPercents', 25)"
        >
          25%
        </li>
      </ul>
      <ul
        v-if="theme.value === 'TAPPER'"
        class="tips__percents-list"
      >
        <li
          class="tips__list-item"
          :class="{ active: percents === 0 && !manualTips }"
          @click="$emit('selectPercents', 0)"
        >
          0%
        </li>

        <li
          class="tips__list-item"
          :class="{
            active: percents === 10 && !manualTips,
            disabled: !availableTips[10],
          }"
          @click="$emit('selectPercents', 10)"
        >
          10%
        </li>

        <li
          class="tips__list-item"
          :class="{
            active: percents === 15 && !manualTips,
            disabled: !availableTips[15],
          }"
          @click="$emit('selectPercents', 15)"
        >
          15%
        </li>

        <li
          class="tips__list-item"
          :class="{
            active: percents === 20 && !manualTips,
            disabled: !availableTips[20],
          }"
          @click="$emit('selectPercents', 20)"
        >
          20%
        </li>

        <li
          class="tips__list-item"
          :class="{
            active: percents === 25 && !manualTips,
            disabled: !availableTips[25],
          }"
          @click="$emit('selectPercents', 25)"
        >
          25%
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderTips',
  inject: ['theme', 'tips_type'],

  props: {
    waiter: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    tipsType: {
      type: String,
      required: false,
      default: 'TAPPER',
    },

    manualTips: {
      type: Boolean,
      required: true,
      default: false,
    },

    tipsLink: {
      type: [String, null],
      default: null,
    },

    percents: {
      type: Number,
      required: true,
      default: 0,
    },

    tipsValue: {
      type: Number,
      required: true,
      default: 0,
    },

    inputTips: {
      type: Boolean,
      required: true,
      default: false,
    },

    availableTips: {
      type: Object,
      default: () => {},
    },

    errorTips: {
      type: Boolean,
      default: false,
    },

    currencyIcon: {
      type: String,
      default: '-',
    },
  },
  emits: [
    'selectPercents',
    'validTips',
    'resetTipsZero',
    'showInputTips',
    'checkMinTipsError',
  ],
}
</script>

<style scoped lang="scss">
.tips {
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 11px;

  border: 1px solid #e6e7eb;
  border-radius: 11px;

  background-color: #fff;

  &__heading {
    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 17px;
    line-height: 22px;

    &-icon {
      display: flex;
      margin-right: 4px;
    }
  }

  &__waiter {
    margin-top: 16px;
    margin-bottom: 13px;

    & img {
      margin-right: 12px;
    }

    &-info {
      display: inline-flex;
      flex-direction: column;

      vertical-align: top;

      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #343d5d;

      & span:first-child {
        color: #80869a;
      }
    }
  }

  &__sum {
    position: relative;

    padding-bottom: 41px;

    font-weight: 600;
    font-size: 17px;
    line-height: 22px;

    text-align: center;

    border-bottom: 1px solid #edeef1;

    & .custom-input {
      display: flex;
      align-items: center;
      position: absolute;
      left: calc(50% - 35px);

      transform: translateX(-50%);

      & input {
        width: 40px;
        padding: 0;
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        width: 100px;
        border: none;
        text-align: end;
        margin-right: 2px;
      }
    }

    & .close-input {
      position: absolute;
      right: 0;
      z-index: 2;
    }

    &-btn {
      position: absolute;
      right: 0;
      top: 0;

      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      outline: none;
      background: #f7f8fa;
      cursor: pointer;

      border-radius: 50%;
      border: 1px solid #edeef1;
    }
  }

  &__percents {
    padding-top: 18px;

    &-list {
      display: flex;
      justify-content: space-between;
      list-style-type: none;

      & .tips__list-item {
        padding: 4px;

        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #80869a;
        cursor: pointer;

        background: #f7f8fa;
        border-radius: 6px;
        border: 1px solid #f7f8fa;

        &__VTB {
          padding: 4px;

          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #80869a;
          cursor: pointer;

          background: #f7f8fa;
          border-radius: 6px;
          border: 1px solid #f7f8fa;
          &.disabled {
            color: #c8cad2;
            pointer-events: none;
          }
          &:hover {
            color: #010d35;
            border: 1px solid #4c7dd5;
          }
        }

        &.disabled {
          color: #c8cad2;
          pointer-events: none;
        }
        @media (min-width: 800px) {
          &:hover {
            color: #010d35;
            border: 1px solid #6764ff;
          }
        }
      }

      & .active {
        color: #010d35;
        border: 1px solid #6764ff;
        &__VTB {
          border: 1px solid #4c7dd5;
          color: #010d35;
        }
      }
    }
  }
  &__error {
    margin-top: 4px;
    font-weight: 500;
    text-align: center;
    color: #ec4e4e;
  }
}
</style>
