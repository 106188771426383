<template>
  <div
    class="vOrderHeader"
    :class="{ 'myOrder-menu': menu }"
  >
    <div class="vOrderHeader__wrap">
      <h1 class="vOrderHeader__title">
        <span v-if="lang === 'en'">My order</span>
        <span v-else-if="lang === 'de'">de</span>
        <span>
          {{ !menu ? 'Мой заказ' : 'Меню' }}
        </span>
      </h1>
      <span
        class="vOrderHeader__table"
      >{{ lang === 'ru' ? 'Стол' : 'Table' }}
        {{ tableCode ? tableCode : 0 }}
      </span>
    </div>

    <div
      v-if="separate"
      class="vOrderHeader__separate"
      :class="{ vOrderHeader__separate__VTB: theme.value === 'VTB' }"
    >
      Другой пользователь разделил счёт
    </div>
  </div>
</template>

<script>
export default {
  name: 'VOrderHeader',
  inject: ['theme'],
  props: {
    tableCode: {
      default: 0,
      type: [Number, String],
      required: false,
    },
    lang: {
      default: 'ru',
      type: String,
      required: false,
    },
    separate: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss">
.vOrderHeader {
  margin-bottom: 24px;

  &__wrap {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }

  &__table {
    color: #80869a;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }

  &__separate {
    text-align: center;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #413dff;
    margin-top: 24px;
    &__VTB {
      color: #4c7dd5;
    }
  }
}
</style>
