<template>
  <div class="mainPage">
    <vMainPreloader v-show="screenSaver" />
    <vMainOrder
      :show-content="!screenSaver"
      @LoadScreen="LoadScreen"
      @onHandler="toggleLimit"
    />

    <vLimitModal
      v-if="limit"
      @toggleLimit="toggleLimit"
    />
    <VSocketNotification :time-out="5000" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import vMainPreloader from '@/components/main/v-main-preloader'
import vMainOrder from '@/components/main/v-main-order'
import vLimitModal from '@/components/order/v-limit-modal'
import VSocketNotification from '@/components/socketNotification/v-socket-notification'
export default {
  name: 'MainPage',
  components: {
    vMainPreloader,
    vMainOrder,
    vLimitModal,
    VSocketNotification,
  },
  data() {
    return {
      limit: false,
      screenSaver: true,
      id: null,
      loadstart: '',
    }
  },
  created() {
    this.loadstart = new Date()
  },
  async mounted() {
    let url = window.location.pathname
    let urlName = url.split('/', 2).slice(1)
    // console.log('Наш URl: ' + urlName);
    await this.FETCH_CUSTOMIZATION_BY_CODE({ code: urlName })
    await this.FETCH_MENU_USER(urlName)
  },
  methods: {
    ...mapActions('alertModalText', ['SET_NEW_TEXT']),
    ...mapActions('menuAdmin', ['FETCH_MENU_USER']),
    ...mapActions('customization', ['FETCH_CUSTOMIZATION_BY_CODE']),
    checkErrorEight() {
      const locationUrl = location.href
      const statusError = locationUrl.includes('code=8')
      const errorOrder =
        locationUrl.includes('error=133') || locationUrl.includes('error=169')
      const cocationArr = locationUrl.split('?')
      window.history.pushState('name', '', cocationArr[0])
      if (statusError) {
        this.SET_NEW_TEXT(
          'Превышен лимит попыток оплаты' +
            '<br/>' +
            'по карте, попробуйте оплатить другой' +
            '<br/>' +
            'картой или обратитесь' +
            '<br/>' +
            'к администратору заведения '
        )
        this.toggleLimit('on')
        setTimeout(() => {
          this.toggleLimit('off')
        }, 4000)
      } else if (errorOrder) {
        this.SET_NEW_TEXT(
          'Время жизни оплаты заказа истекло. <br/> Пожалуйста попробуйте оплатить еще раз'
        )
        this.toggleLimit('on')
        setTimeout(() => {
          this.toggleLimit('off')
        }, 3000)
      }
    },
    toggleLimit(val) {
      switch (val) {
        case 'on':
          this.limit = true
          break
        case 'off':
          this.limit = false
          break
        default:
          this.limit = !this.limit
          break
      }
    },
    LoadScreen(newDate) {
      let a = newDate - this.loadstart
      if (a < 3000) {
        setTimeout(() => {
          this.screenSaver = false
          this.checkErrorEight()
        }, 3000 - a)
      } else {
        this.screenSaver = false
        this.checkErrorEight()
      }
    },
  },
}
</script>

<style lang="scss">
.mainPage {
  width: 100%;
  min-height: 100vh;
  & * {
    font-family: 'Commissioner';
  }
}
</style>
