<template>
  <vue100vh
    class="vh100"
    :css="{ height: '100rvh' }"
  >
    <div
      class="startScreen"
      style="background: #fff"
    >
      <div class="tapper__logo">
        <img
          class="logo_img"
          :src="getImgUrl()"
          alt=""
        >
        <!--        <img-->
        <!--            v-if="img == 'nalubaloo'"-->
        <!--            style="width: 100%"-->
        <!--            src="@/assets/images/naluBaloo.gif"-->
        <!--            alt="nalubaloo"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'allday'"-->
        <!--            style="width: 50%"-->
        <!--            src="@/assets/images/allday.gif"-->
        <!--            alt="allday"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'tapper'"-->
        <!--            style="width: 50%"-->
        <!--            src="@/assets/images/white.gif"-->
        <!--            alt="tapper"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'novikov'"-->
        <!--            style="width: 50%"-->
        <!--            src="@/assets/images/novikov.gif"-->
        <!--            alt="novikov"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'achachacha198'"-->
        <!--            style="width: 150%"-->
        <!--            src="@/assets/images/AchaChacha.gif"-->
        <!--            alt="achachacha"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'almond'"-->
        <!--            style="width: 100%"-->
        <!--            src="@/assets/images/almond.gif"-->
        <!--            alt="almond"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'moo-moo-burgers-and-grill'"-->
        <!--            style="width: 100%"-->
        <!--            src="@/assets/images/MooMoo-red-bg.gif"-->
        <!--            alt="moo-moo"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'teplica'"-->
        <!--            style="width: 180%"-->
        <!--            src="@/assets/images/teplica.gif"-->
        <!--            alt="teplica"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'pivaldi'"-->
        <!--            style="width: 100%"-->
        <!--            src="@/assets/images/pivaldi.gif"-->
        <!--            alt="teplica"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'biblioteca'"-->
        <!--            style="width: 100%"-->
        <!--            src="@/assets/images/biblioteca.gif"-->
        <!--            alt="kafe-biblioteca"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'fonoteka'"-->
        <!--            style="width: 300%"-->
        <!--            src="@/assets/images/fonoteka1.gif"-->
        <!--            alt="kafe-biblioteca"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'brisket'"-->
        <!--            style="width: 100%"-->
        <!--            src="@/assets/images/brisket.gif"-->
        <!--            alt="brisket"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'kuhnya'"-->
        <!--            style="width: 150%"-->
        <!--            src="@/assets/images/kuhnya.gif"-->
        <!--            alt="kuhnya"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'deribas'"-->
        <!--            style="width: 100%"-->
        <!--            src="@/assets/images/deribas.gif"-->
        <!--            alt="deribas"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == '9wines'"-->
        <!--            style="width: 200%"-->
        <!--            src="@/assets/images/9wines.gif"-->
        <!--            alt="9wines"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'acha'"-->
        <!--            style="width: 150%"-->
        <!--            src="@/assets/images/acha.gif"-->
        <!--            alt="deribas"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'kofe-i-lyudi'"-->
        <!--            style="width: 150%"-->
        <!--            src="@/assets/images/kofe-i-lyudi.gif"-->
        <!--            alt="kofe-i-lyudi"-->
        <!--        >-->
        <!--        <img-->
        <!--            v-if="img == 'laffa-laffa-bronnaya'"-->
        <!--            style="width: 150%"-->
        <!--            src="@/assets/images/25-Laffa.gif"-->
        <!--            alt="laffa-laffa-bronnaya"-->
        <!--        >-->
      </div>
    </div>
  </vue100vh>
</template>

<script>
import axios from 'axios'
import vue100vh from 'vue-100vh'
export default {
  name: 'VDemoPreloader',
  components: { vue100vh },
  data() {
    return {
      domain: '',
      background: '#fff',
      size: '',
      gif: '',
      loading: true,
      Location: '',
    }
  },

  beforeMount() {
    this.Location = window.location.pathname.split('/').slice(1)[0]
    // axios.get(`${process.env.VUE_APP_BASE_URL}/shop/pre-loader/${this.Location}`).then(res=>{
    //   const {code_color, size} = res.data.result
    //   this.background = code_color || '#000033'
    //   this.size = size || '50%'
    //   this.loading = false
    // }).catch(err => {
    //   this.background = '#000033';
    //   this.size = '50%'
    //   this.loading = false
    // })
  },
  mounted() {
    //демки для разных заведений
    //const sub = this.$route.params.shop
    // switch (sub) {
    //   case 'nalubaloo':
    //     this.img = "nalubaloo"
    //     this.background = '#fff'
    //     break;
    //   case 'allday':
    //     this.img = "allday"
    //     this.background = '#375CC7'
    //     break;
    //   case 'novikov':
    //     this.img = "novikov"
    //     this.background = '#fff'
    //     break;
    //   case 'acha-chacha-198':
    //     this.img = "achachacha198"
    //     this.background = '#fff'
    //     break;
    //   case 'acha-chacha-pyatnickaya-254':
    //     this.img = "achachacha198"
    //     this.background = '#fff'
    //     break;
    //   case 'almond':
    //     this.img = "almond"
    //     this.background = '#fff'
    //     break;
    //   case 'moo-moo-burgers-and-grill':
    //     this.img = "moo-moo-burgers-and-grill"
    //     this.background = '#e30002'
    //     break;
    //   case 'kafe-teplica-229':
    //     this.img = "teplica"
    //     this.background = '#fff'
    //     break;
    //   case 'pivaldi':
    //     this.img = "pivaldi"
    //     this.background = '#fff'
    //     break;
    //   case 'kafe-biblioteca-151':
    //     this.img = "biblioteca"
    //     this.background = '#fff'
    //     break;
    //   case 'kafe-fonoteca-256':
    //     this.img = "fonoteka"
    //     this.background = '#fff'
    //     break;
    //   case 'brisket-ceh-342':
    //     this.img = "brisket"
    //     this.background = '#fff'
    //     break;
    //   case 'the-kuhnya-259':
    //     this.img = "kuhnya"
    //     this.background = '#fff'
    //     break;
    //   case 'deribas-435':
    //     this.img = "deribas"
    //     this.background = '#fff'
    //     break;
    //   case '9wines':
    //     this.img = "9wines"
    //     this.background = '#fff'
    //     break;
    //   case 'acha-chacha-staropetrovskaya-300':
    //     this.img = "acha"
    //     this.background = '#fff'
    //     break;
    //   case 'kofe-i-lyudi-228':
    //     this.img = "kofe-i-lyudi"
    //     this.background = '#fff'
    //     break;
    //   case 'laffa-laffa-bronnaya-4-509':
    //     this.img = "laffa-laffa-bronnaya"
    //     this.background = '#fff'
    //     break;
    //   default:
    //     this.img = "tapper"
    //     this.background = '#000033';
    // }

    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  },
  methods: {
    getImgUrl() {
      return `${process.env.VUE_APP_IMG_URL}/loader/${this.Location}.gif`
    },
  },
}
</script>

<style lang="scss">
.logo_img {
  display: block;
  object-fit: cover;
  max-width: 100vw;
  height: 100vh;
}
.startScreen {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: #000033;
  & .tapper__logo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
.vh100 {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}
</style>
