<template>
  <div
    v-show="!menu"
    class="vEmptyOrderModal"
  >
    <!--    <img-->
    <!--      src="@/assets/images/clock_logo.svg"-->
    <!--      alt="Изображение часов"-->
    <!--      class="vEmptyOrderModal__icon"-->
    <!--    >-->
    <img
      src="@/assets/images/NY_clock_logo.svg"
      alt="Изображение часов"
      class="vEmptyOrderModal__icon"
    >
    <!--    <img-->
    <!--      src="@/assets/images/clock_logo.svg"-->
    <!--      alt="Изображение часов"-->
    <!--      class="vEmptyOrderModal__icon"-->
    <!--    >-->
    <h3 class="vEmptyOrderModal__heading">
      {{
        paidOrder ? 'Заказ успешно оплачен' : 'Скоро здесь появится ваш заказ'
      }}
    </h3>
  </div>
</template>

<script>
export default {
  name: 'VEmptyOrderModal',

  props: {
    paidOrder: {
      type: Boolean,
      required: true,
      default: false,
    },
    menu: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.vEmptyOrderModal {
  margin: 130px 0 31px 0;
  padding: 17px 0;
  text-align: center;
  &__icon {
    margin-bottom: 18px;
  }
  &__heading {
    margin: 0 auto 16px auto;
    max-width: 298px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
}
</style>
