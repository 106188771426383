<template>
  <div class="vLimitModal">
    <div
      class="vLimitModal__overlay"
      @click="toggleLimit"
    />
    <div
      class="vLimitModal__content"
      v-html="GET_TEXT"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'VLimitModal',
  emits: ['toggleLimit'],
  computed: {
    ...mapGetters('alertModalText', ['GET_TEXT']),
  },
  methods: {
    toggleLimit() {
      this.$emit('toggleLimit')
    },
  },
}
</script>
<style lang="scss">
.vLimitModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(220, 222, 227, 0.5);
    backdrop-filter: blur(7px);
  }
  &__content {
    background: #fff;
    border-radius: 12px;
    padding: 24px 20px;
    color: #010d35;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    font-family: 'Commissioner';
    position: relative;
    z-index: 2;
    text-align: center;
  }
}
</style>
