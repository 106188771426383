import axios from 'axios'

const checkingIfRestWorksResponse = async (requestData) => {
  return await axios
    .get(`${process.env.VUE_APP_BASE_URL}/shop/availability/${requestData}`)
    .then((response) => {
      return response.data
    })
}

export default checkingIfRestWorksResponse
